import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CommonService, DataSharingService } from '../services/commom.service';



@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit {
  projects:any;
  cpDetails:any;
  modalRef?: BsModalRef;
 
  constructor(private dataSharingService:DataSharingService, private commonService:CommonService, private router: Router, private modalService:BsModalService) {
    // this.dataSharingService.mobileNo = "&#39;+91-9172342341";
   }

  ngOnInit(): void {
   
    this.getActiveProjects();
    this.getCpDetails();
  }
  openHelp(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login')

  }
  getActiveProjects(){
    this.commonService.getActiveProjects().subscribe(
      (response)=>{
        if (response.success == true) {
          // console.log("Projects", response['payload']['projects'])
          this.dataSharingService.setProjects(response['payload']['projects']);

        }
      },
      (error)=>{},
    );
  }
 getCpDetails(){
  let mobileNo;
  if(this.dataSharingService.getMobileNo()) {
    mobileNo = this.dataSharingService.getMobileNo()
  }
  let body = {
    "mobileNumber": "+91-" + mobileNo
  }
  this.commonService.getCPByPhone(body).subscribe((data) => {
    if(data && data.payload && data.payload.cpMaster) {
      this.dataSharingService.setCpDetails(data['payload']['cpMaster']);
          
      this.cpDetails = data['payload']['cpMaster'];
      console.log(this.cpDetails); 
    }
  })
}
}



