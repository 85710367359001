import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
/* import { NgxDatatableComponent } from '@tusharghoshbd/ngx-datatable'; */
import { CommonService, DataSharingService } from '../services/commom.service';
import { FileService } from '../services/file.service';
import { v4 as uuid } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import * as XLSX from 'xlsx';
import { createButtonConfig, ImageViewerConfig, IMAGEVIEWER_CONFIG } from '@hallysonh/ngx-imageviewer';
import { Router } from '@angular/router';

const MY_IMAGEVIEWER_CONFIG: ImageViewerConfig = {
  
  zoomOutButton: { // zoomOut button config
    
    show: false // used to show/hide the button
  },
  nextPageButton: {
    show:false
  },
  zoomInButton: {
    show:false
  },
  rotateLeftButton: {
    show:false
  },
  rotateRightButton: {
    show:false
  },
  resetButton: {
    show:false
  },
  beforePageButton: {
    show:false
  },
  buttonStyle: {
    bgStyle: "#ffff"
  }
 
  
};

@Component({
  selector: 'app-advance',
  templateUrl: './advance.component.html',
  styleUrls: ['./advance.component.css'],
  providers: [
    {
      provide: IMAGEVIEWER_CONFIG,
      useValue: MY_IMAGEVIEWER_CONFIG
    }
  ]
})
export class AdvanceComponent implements OnInit {

   
  activeProjects:any[];
  displayHeader  = "AdvancedPayments"
  rowData;
  private _canvasDim = { width: 300, height: 600 };
  @ViewChild('viewerwrapper', { static: false }) viewerwrapper: ElementRef;
  get canvasDim() {
    return this._canvasDim;
  }
  pdfSrc;
  docsArr = [];
  fileLength;
  selectedProject:any;
  xrCode:any;
  /* @ViewChild(NgxDatatableComponent, { static: false }) table: NgxDatatableComponent; */
  @ViewChild('actionTpl', { static: true }) actionTpl: TemplateRef<any>;
  @ViewChild('startTpl', { static: true }) startTpl: TemplateRef<any>;
  @ViewChild('detailsTpl', { static: true }) detailsTpl: TemplateRef<any>;
  options = {
    rowClickEvent: true,
    rowPerPageMenu: [5, 10, 20, 30],
    rowPerPage: 5,
    
  }

// -------------- REPLACE
projectFilter = "All Projects";
projectListforCp=[];
totalAmount : any;
advanceAdjustedAmount : any;
totalAdvancesCount : any;
currentResultsHeader = [
]
currentResults=[];
advanceRedeemedCount : any;
advanceRedeemInProgressCount : any;
imageRef: BsModalRef;
showView: boolean = false;
// ----------------------

  constructor(
    private modalService: BsModalService,
    private dataSharingService: DataSharingService, private route: Router, private cd: ChangeDetectorRef, private toasterService: ToastrService, private commonService:CommonService, private fileService: FileService
  ) { }

  ngOnInit(): void {
    // console.log(this.actionTpl)
    this.activeProjects = this.dataSharingService.getProjects();
    this.xrCode = this.dataSharingService.getCpDetails()['xrCode'];
    this.getBrokerageIncentive();
    
  }
 
 
  
  onRowClick(event){
    /* console.log(event) */
  }
  stats:any;
  getBrokerageIncentive(){
    let payload = {};
    payload["xrCode"] = this.xrCode;
    if(this.projectFilter!="All Projects" && this.projectFilter !=''){
        payload["project"] = this.projectFilter;
    }
    this.commonService.getAdvanceIncentive(payload).subscribe(
        (response)=>{
          if (response.success) {
            this.currentResultsHeader = response['payload']['headerList']
           this.currentResultsHeader.forEach((element) => {
              if(element.title === 'start date') {
                element.cellTemplate = this.startTpl
              }
              
            }) 
            /* if(this.projectFilter!="All Projects" && this.projectFilter !=''){
              this.currentResults = response['payload']['netDealsGrouped'];
          }
          else {
          

          } */
          this.currentResults = response['payload']['advancePayment'];
           
            this.stats=response.payload;
            if(this.projectFilter =="All Projects"){
              this.projectListforCp = response.payload.projects;
            }
            this.totalAmount = Math.ceil(response['payload']['totalAmount']);
            this.advanceAdjustedAmount = Math.ceil(response['payload']['advanceAdjustedAmount']);
            this.totalAdvancesCount = response['payload']['totalAdvancesCount'];
            this.advanceRedeemedCount = response['payload']['advanceRedeemedCount'];
            this.advanceRedeemInProgressCount = response['payload']['advanceRedeemInProgressCount'];
          }
        },
        (error)=>{},
      );

  }
  changeRoute(item:any) {
    this.route.navigateByUrl("/"+item)
  }
  viewPlot(actionType, plotNumber?) {
    if(actionType === 'view')
    {
    this.showView = !this.showView
    let payload = {};
    payload["uniquePlotNumber"] = plotNumber;

    this.commonService.viewBrokerageIncentive(payload).subscribe(
      (response)=>{
        if (response.success) {
          this.currentResultsHeader = response['payload']['headerList']
          this.currentResultsHeader.forEach((element) => {
            if(element.title === 'Invoice Document') {
              element.cellTemplate = this.actionTpl;
            }
            if(element.title === 'View Details') {
              element.cellTemplate = this.detailsTpl
              element.width = 167
              console.log(this.detailsTpl)
            }
          })
          this.currentResults = response['payload']['netDeals']
        }
      },
      (error)=>{},
    );
    }
    else {
      this.showView = !this.showView
      this.getBrokerageIncentive();
    }
   

  }
  onDocumentUpload(event, rowType) {
    // console.log(rowType);
    let eligibilityStatus = rowType['eligibilityStatus'];
    let invoiceStatus = rowType['invoiceStatus'];
    if((!invoiceStatus && eligibilityStatus=="Yes") || invoiceStatus=="Rejected"){
   this.fileLength = event.target.files.length
   this.docsArr = []
    for (let index = 0; index < event.target.files.length; index++) {
      let uploadedFile = event.target.files[index];
      const fileInputForm = new FormData();
      const file: File = uploadedFile
      fileInputForm.append('file', file, file.name);
      var uploadFileName = uuid(); 
      fileInputForm.append("fileName", file.name);
      fileInputForm.append("functionInstanceName", "Activity");
      fileInputForm.append("entityType", "ACTIVITY_" + rowType._id);
      fileInputForm.append("entityRef", uploadFileName);  
      this.uploadFile(fileInputForm, rowType);
    }
  }
  else{
    if(invoiceStatus=="Invoice Uploaded" || invoiceStatus=="Accepted"){
      this.toasterService.error("Invoice has already been uploaded.")
    }
    else {
    this.toasterService.error("Not eligible to upload the file.")
    }
  }

  }
  uploadFile(formData: FormData, rowType) {
    this.fileService.upload(formData)
      .subscribe(
        response => {
          if (response && response["url"] && response["fileName"]) {
            
              let doc: any = {};
              doc.url = response["url"];
              doc.fileName = response["fileName"];
              doc.downloadFileUrl = response["downloadFileUrl"];
              doc.fullDataUrl = response["fullDataUrl"];
              doc.fullFileUrl = response["fullFileUrl"];
              this.docsArr.push(doc)
              this.submitDoc(rowType)
              
             
              
            
            }
            
          })
          
        };

submitDoc(rowType) {
  if(this.fileLength == this.docsArr.length) {
    rowType["invoiceDocuments"] = this.docsArr[0];
    rowType['invoiceStatus'] = "Invoice Uploaded";
    if("_autoId" in rowType) {
      delete rowType._autoId
    }
    console.log("rowType", rowType)
    let payload = {
      "netDeal": rowType
    }
    this.commonService.updateNetDeals(payload).subscribe((data) => {
      if(data && data.payload) {
        this.toasterService.success("File Uploaded Successfully")
        this.fileLength = 0;
        this.getBrokerageIncentive();
    
      }
    })

  }

}
downloadFile(){
  // console.log(row);
  let fileName = this.rowData.invoiceDocuments.downloadFileUrl;
  console.log(fileName);
  this.fileService.download(fileName).subscribe((data) => {

  })
}
viewImage(content: TemplateRef<any>, rowItem) {
  this.rowData = rowItem
  if(this.rowData.invoiceDocuments)
  {
  this.getDownloadFileObjectUrl(this.rowData.invoiceDocuments)
  this.imageRef = this.modalService.show(content)
  }
  else {
    this.toasterService.error("Please Upload File to preview")
  }

}
getDownloadFileObjectUrl(doc) {
  console.log(doc)
  
      this.fileService.download(doc.downloadFileUrl, true).subscribe(
        objectUrl => {
          if (objectUrl) {
            var type = (objectUrl.split(';')[0]).replace("data:","");
            fetch(objectUrl)
            .then(res => res.blob())
            .then(blob => {
              const file = new File([blob], doc.fileName,{ type: type })
              this.pdfSrc = (file);
            })
            // this.downloadFileObjectUrl = objectUrl;
          }
        }
      );
    }

    exportFile() {
    
      let element = document.getElementById('excel-table');
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
   
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
   
      /* save to file */  
      XLSX.writeFile(wb, this.displayHeader+".xlsx");
  
    }
  
    downloadInvoiceFile(){
  
      this.fileService.download("/api/downloadDataFile/FILEREADER_TEST/FILEREADER_TEST/Test/null/Channel Partner - Invoice Format.xlsm").subscribe((data) => {
    
      })
    }



}
