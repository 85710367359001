<header id="header" class="header fixed-top d-flex align-items-center justify-content-between">

    <div class="d-flex align-items-center justify-content-between" style="width: 10%;">
        <a href="#" class="logo d-flex align-items-center">
            <img src="assets/images/logo-01.png" style="width: 100%;" alt="Hobl Logo">
        </a>

    </div><!-- End Logo -->
   
</header><!-- End Header -->
<section class="vh-100">
    <div class="container py-5 h-100">
        <div class="row d-flex align-items-center justify-content-center h-100">
            <div class="col-md-8 col-lg-7 col-xl-6">
                <owl-carousel-o [options]="customOptions">

                    <ng-container *ngFor="let slide of slidesStore">
                        <ng-template carouselSlide [id]="slide.id">
                            <img [src]="slide.src" [alt]="slide.alt" [title]="slide.title">
                        </ng-template>
                    </ng-container>

                </owl-carousel-o>
            </div>
            <div class="col-md-7 col-lg-5 col-xl-5">
               <!--  <div class="row">
                    <div class="col-md-10">
                        <p class="partner__para">Welcome to the BLUECHIP LAND CLUB</p>
                    </div>
                </div> -->
               <div class="row">
                    <div class="col-md-12 mt-3 text-center">
                        <img class="login__img" alt="Hobl Logo" src="assets/images/logoHobl.png">
                    </div>
                    
                </div> 
                <div class="ml-3">
                <h5>Sign In</h5>
                <!-- Email input -->
                <div class="form-outline">
                    <label class="form-label" for="phone">Phone</label>
                    <input #mobError type="text" class="form-control  form-control-lg" (blur)="onBlurEvent($event.target.value)" (keydown.enter)="search($event.target.value)" name="mobileNo" id="mobileNo" [(ngModel)]="mobileNo">
                    <p class="errormsg hiddenDiv" id="mobError"><small><strong>Please enter valid phone
                                number</strong></small></p>
                </div>

                <!-- Password input -->
                <div class="form-outline mb-4 showAfterOTP">
                    <label class="form-label" for="otp">OTP</label>
                    <input type="password" class="form-control form-control-lg" name="otp" id="otp" [(ngModel)]="otp">
                    <p class="errormsg hiddenDiv" id="otpError"><small><strong>Please enter valid OTP</strong></small>
                    </p>

                </div>

                <!-- Submit button -->
                <button class="btn btn-danger btn-block hideAfterOTP mt-4 mb-2" type="submit" name="sendOtpBtn" (click)="sendOtpBtn()">Send
                    OTP</button>
                <button class="btn btn-danger btn-block showAfterOTP" type="submit" name="verifyOtp" (click)="verifyOtpBtn()">Verify</button>
                <button class="btn  btn-block showAfterOTP" type="button" name="sendOtpBtn" (click)="sendOtpBtn()">Resend OTP</button>
                <a style="text-decoration: underline;"  href="https://forms.hoabl.in/cp-registration">New user? Register here</a>
            </div>
            </div>
        </div>
    </div>
</section>