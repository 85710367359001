<header id="header" class="header fixed-top d-flex align-items-center justify-content-between">

    <div class="d-flex align-items-center justify-content-between header_item">
        <a href="#" class="logo d-flex align-items-center">
            <img src="../../assets/images/logoMain.png"  alt="">
        </a>

    </div><!-- End Logo -->
    <div *ngIf="cpDetails">
        <p style="text-align: center; font-size: 16px; font-weight: 600; margin-left: 0; margin-top: 10px;">{{cpDetails['cpName']}} : {{cpDetails['xrCode']}}</p>
    </div>
    <div>
      <!--   <button  data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Tooltip on top"><i class="bi bi-info-circle logout_icon"></i></button> -->
    <!--   data-toggle="tooltip" data-placement="top" data-html="true" title="In case of queries or any help reach us at :- channelpartnersupport@hoabl.in" -->
      <button type="button" class="btn btn-info" (click)="openHelp(template)">
        
        <i class="bi bi-info-circle logout_icon"></i>
      </button>
        <i class="bi bi-box-arrow-right logout_icon" (click)="logout()"></i>
    </div>
</header><!-- End Header -->


<!--Cards Section-->
<main class="main-body">
    <div class="container-fluid">
        <router-outlet></router-outlet>
    </div>
</main>
<ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Resolve your query</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>In case of queries or any help reach us at :- <a href="mailto:channelpartnersupport@hoabl.in">channelpartnersupport@hoabl.in</a></p>
    </div>
  </ng-template>
