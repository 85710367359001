import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommonService, DataSharingService } from '../services/commom.service';
import { FileService } from '../services/file.service';

interface FileReaderEventTarget extends EventTarget {
  result: string
}

interface FileReaderEvent extends Event {
  target: FileReaderEventTarget;
  getMessage(): string;
}

@Component({
  selector: 'app-cp-projects',
  templateUrl: './cp-projects.component.html',
  styleUrls: ['./cp-projects.component.css']
})
export class CpProjectsComponent implements OnInit {
  projects: any[];
  imageData;
  cpDetails: any;
  applicationCount: any;
  netDealsCount: any;
  totalApplicationIncentive: any;
  totalInvoice: any;
  totalPaid: any;
  totalEarning: any;
  totalCP: any;
  totalDeals: any;
  xrcode: any;
  imgArr = []
  brochureFileName = []
  whatsappFileName = []
  incentiveFileName = []
  modalRef?: BsModalRef;
  whatsappRef: BsModalRef;
  incentiveRef: BsModalRef;
  customOptions1: OwlOptions = {
    margin: 0,
    dots:false,
    nav: true,
    navText:["<i class='fas fa-chevron-left'></i>","<i class='fas fa-chevron-right'></i>"],
    animateOut: 'fadeOut',
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 3
      }
    }
  };

  constructor(private dataSharingService: DataSharingService, private bs: BsModalService, private sanitizer: DomSanitizer, private commonService: CommonService, private fileService: FileService) {
    this.getActiveProjects();
    setTimeout(() => { 
      this.cpDetails = this.dataSharingService.getCpDetails();
    }, 1000);
  }

  ngOnInit(): void {
   
  }
  getActiveProjects() {
    this.projects = this.dataSharingService.getProjects();
    let payload = {
      "xrCode": this.dataSharingService.getCpDetails()['xrCode']
    }
    this.commonService.getCPDashboardData(payload).subscribe((data) => {
      if (data && data.payload) {
        
          this.applicationCount = data.payload.applicationsCount
    
      
          this.netDealsCount = data.payload.netDealsCount

      
       
          this.totalApplicationIncentive = Math.ceil(data.payload.totalApplicationIncentive).toLocaleString('en-IN')

      
       
          this.totalCP = Math.ceil(data.payload.totalCPLadderInvoiceAmount).toLocaleString('en-IN')

        
        
          this.totalDeals = Math.ceil(data.payload.totalNetDealsBrokerage).toLocaleString('en-IN')

       
       
          this.totalInvoice = Math.ceil(data.payload.invoiceRaised)

       
          this.totalPaid =  Math.ceil(data.payload.totalPaid).toLocaleString('en-IN')

       
       
          this.totalEarning = Math.ceil(data.payload.totalEarning).toLocaleString('en-IN')

       
       
        
      
       
       
       
       
       
      }
    })

    for (let index = 0; index < this.projects.length; index++) {
      const element = this.projects[index];
      for (let imgInd = 0; imgInd < element.images[0].length; imgInd++) {
        const img = element.images[0][imgInd];
        this.displayFile(img.downloadFileUrl, index)

      }
    }
  }
  getImage(imgUrl) {
    return this.sanitizer.bypassSecurityTrustUrl(imgUrl)

  }
  downloadPDF(fileName) {
    this.fileService.download(fileName).subscribe((data) => {

    })
  }
  displayLatest3Doc(arrayList){
    let newArrayList = [];
    let count = 0;
    for(let i = arrayList.length-1;i>-1;i--){
      let item = arrayList[i];
      newArrayList.push(item);
      count++;
      if(count>2){
        break;
      }
    }
    return newArrayList;
  }
  openModal(template: TemplateRef<any>, brochureArray: any) {
    this.brochureFileName = []
    this.modalRef = this.bs.show(template);
    if (brochureArray.length > 0) {
      brochureArray = this.displayLatest3Doc(brochureArray);
      brochureArray =  [].concat.apply([],brochureArray)
      brochureArray.map(element => {
        this.brochureFileName.push({
          "fileName": element.fileName,
          "downloadURL": element.downloadFileUrl
        })


      });
      

    }
  }
  openWhatsappModal(template: TemplateRef<any>, whatsappArray: any) {
    this.whatsappFileName = []
    this.whatsappRef = this.bs.show(template);
    if (whatsappArray.length > 0) {
      whatsappArray = this.displayLatest3Doc(whatsappArray);
      whatsappArray =  [].concat.apply([],whatsappArray)
      whatsappArray.map(element => {
        this.whatsappFileName.push({
          "fileName": element.fileName,
          "downloadURL": element.downloadFileUrl
        })


      });
     

    }
  }
 
  openIncentiveModal(template: TemplateRef<any>, incentiveArray: any) {
    this.incentiveFileName = []
    this.incentiveRef = this.bs.show(template);
    if (incentiveArray.length > 0) {
      incentiveArray = this.displayLatest3Doc(incentiveArray);
      incentiveArray =  [].concat.apply([],incentiveArray)
      incentiveArray.map(element => {
        this.incentiveFileName.push({
          "fileName": element.fileName,
          "downloadURL": element.downloadFileUrl
        })


      });
      
      

    }
  }
  displayFile(fileurl, index) {
    this.fileService.view(fileurl).subscribe(
      (response) => {
        /*    this.imgArr.push(URL.createObjectURL(response)) */
        this.projects[index].imgUrl = URL.createObjectURL(response)

        /*    
           this.imageData = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(response))  */
        const reader = new FileReader();
        reader.addEventListener('loadend', (e: FileReaderEvent) => {
          const img = e.target.result;

        });
        reader.readAsText(response);

      },
      (error) => { },
    );
  }
}
