<div class="row">
    <div class="col-lg-10 offset-lg-1">
        <div class="row">
            <div class="col-xxl-2 col-sm-3 col-md-3">
                <div class="card info-card sales-card shadow-sm bg-white rounded" style="padding-bottom:0px;">


                    <div class="card-body case__box">
                        <h5>{{applicationCount }}</h5>
                        <div class="col-12" style="text-align: center;color: #707070;">
                            <span class="small pt-1" style="text-align: center;font-weight: 600; font-size: 16px;">Applications</span>
                        </div>
                    </div>


                </div>
            </div>
            <div class="col-xxl-2 col-sm-3 col-md-3">
                <div class="card info-card sales-card shadow-sm bg-white rounded" style="padding-bottom:0px;">

                    <div class="card-body case__box">
                        <h5>{{ netDealsCount }}</h5>
                        <div class="col-12" style="text-align: center;color: #707070;">
                            <span class="small pt-1" style="text-align: center; font-weight: 600; font-size: 16px;">Bookings</span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-xxl-2 col-sm-3 col-md-3">
                <div class="card info-card sales-card shadow-sm bg-white rounded" style="padding-bottom:0px;">

                    <div class="card-body case__box">
                        <h5>{{ totalInvoice }}</h5>
                        <div class="col-12" style="text-align: center;color: #707070;">
                            <span class="small pt-1" style="text-align: center;font-weight: 600; font-size: 16px;">Invoice Raised</span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-xxl-2 col-sm-3 col-md-3">
                <div class="card info-card sales-card shadow-sm bg-white rounded" style="padding-bottom:0px;">

                    <div class="card-body case__box">
                        <h5>₹ {{ totalPaid }}</h5>
                        <div class="col-12" style="text-align: center;color: #707070;">
                            <span class="small pt-1" style="text-align: center;font-weight: 600; font-size: 16px;">Total Paid</span>
                        </div>
                    </div>

                </div>
            </div>
           
            
           
          
            
        </div>
        <div class="row mt-2">
            <div class="col-xxl-2 col-sm-3 col-md-3">
                <div class="card info-card sales-card shadow-sm bg-white rounded" style="padding-bottom:0px;">

                    <a href="javascript:void(0);" [routerLink]="['/app']" routerLinkActive="router-link-active">
                        <div class="card-body case__box box_1">
                            <h5>₹ {{totalApplicationIncentive }}</h5>
                            <div class="col-12" style="text-align: center;color: #707070;">
                                <span class="small pt-1" style="text-align: center;font-weight: 600;  font-size: 15px;">Application
                                    Incentive</span>
                            </div>
                        </div>
                    </a>

                </div>
            </div>
            <div class="col-xxl-2 col-sm-3 col-md-3">
                <div class="card info-card sales-card shadow-sm bg-white rounded" style="padding-bottom:0px;">

                    <a href="javascript:void(0);" [routerLink]="['/payments']" routerLinkActive="router-link-active">
                        <div class="card-body case__box box_1">
                            <h5>₹ {{totalDeals }}</h5>
                            <div class="col-12" style="text-align: center;color: #707070;">
                                <span class="small pt-1" style="text-align: center;font-weight: 600;  font-size: 16px;">Base Earnings</span>
                            </div>
                        </div>
                    </a>

                </div>
            </div>
            <div class="col-xxl-2 col-sm-3 col-md-3">
                <div class="card info-card sales-card shadow-sm bg-white rounded" style="padding-bottom:0px;">

                    <a href="javascript:void(0);" [routerLink]="['/ladder']" routerLinkActive="router-link-active">
                        <div class="card-body case__box box_1">
                            <h5>₹ {{ totalCP  }}</h5>
                            <div class="col-12" style="text-align: center;color: #707070;">
                                <span class="small pt-1"
                                    style="text-align: center;font-weight: 600;  font-size: 16px; cursor: pointer;">Ladder Incentive</span>
                            </div>
                        </div>
                    </a>

                </div>
            </div>
            <div class="col-xxl-2 col-sm-3 col-md-3">
                <div class="card info-card sales-card shadow-sm bg-white rounded" style="padding-bottom:0px;">

                    <div class="card-body case__box">
                        <h5>₹ {{ totalEarning }}</h5>
                        <div class="col-12" style="text-align: center;color: #707070;">
                            <span class="small pt-1" style="text-align: center;font-weight: 600; font-size: 16px;">Total Earning</span>
                        </div>
                    </div>

                </div>
            </div>
            
            
            
            
          
           
        </div>
        <div class="row mt-4" *ngIf="projects && projects.length>0">
            <div class="col-md-12">
                <owl-carousel-o [options]="customOptions1">
                    <ng-template carouselSlide *ngFor="let project of projects; let i=index">

                        <div class="col-xxl-12 col-md-12">
                            <div class="card">

                                <div class="card-body">
                                    <img [src]="getImage(project.imgUrl)" class="card-img-top" alt="Image 4">
                                    <!--  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> -->
                                    <div class="d-flex align-items-center justify-content-between mt-4">
                                        <div class="d-flex align-items-center download__btn">
                                            <img class="download__img" src="../../assets/images/brochure.png">
                                            <span class="download__p"
                                                (click)="openModal(template, project.brochure)">Download
                                                Brochures</span>
                                        </div>
                                        <div class="d-flex align-items-center download__btn">

                                            <img class="download__img" src="../../assets/images/customer communication.png">
                                            <span class="download__p"
                                                (click)="openWhatsappModal(whatsappTemp, project.whatsappContents)">Customer Communications</span>
                                        </div>
                                        <div class="d-flex align-items-center download__btn">

                                            <img class="download__img" src="../../assets/images/incentive.png">
                                            <span class="download__p"
                                                (click)="openIncentiveModal(incentiveTemp, project.incentiveSchemes)">Incentive
                                                Schemes</span>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>


        </div>

    </div>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Brochures</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4" *ngFor="let item of brochureFileName">

                <i class="bi bi-filetype-pdf pdf__icon"></i>
                <p>{{ item.fileName }}</p>
                <button class="btn-primary" (click)="downloadPDF(item.downloadURL)">Download</button>

            </div>
        </div>

    </div>
</ng-template>

<ng-template #whatsappTemp>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Whatsapp Contents</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="whatsappRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4" *ngFor="let item of whatsappFileName">

                <i class="bi bi-filetype-docx pdf__icon"></i>
                <p>{{ item.fileName }}</p>
                <button class="btn-primary" (click)="downloadPDF(item.downloadURL)">Download</button>

            </div>
        </div>

    </div>
</ng-template>

<ng-template #incentiveTemp>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Incentive Schemes</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="incentiveRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4" *ngFor="let item of incentiveFileName">

                <i class="bi bi-filetype-docx pdf__icon"></i>
                <p>{{ item.fileName }}</p>
                <button class="btn-primary" (click)="downloadPDF(item.downloadURL)">Download</button>

            </div>
        </div>

    </div>
</ng-template>