import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { CpProjectsComponent } from './cp-projects/cp-projects.component';
import { CpPaymentsComponent } from './cp-payments/cp-payments.component';
import { CpReportsComponent } from './cp-reports/cp-reports.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonService, DataSharingService } from './services/commom.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { FileService } from './services/file.service';
import { NgxDatatableModule } from '@tusharghoshbd/ngx-datatable';
import { CpLadderComponent } from './cp-ladder/cp-ladder.component';
import { CpAppComponent } from './cp-app/cp-app.component';
import { ModalModule } from 'ngx-bootstrap';
import { LoaderComponent } from './loader/loader.component'; 
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { LoaderService } from './loader.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
import { AdvanceComponent } from './advance/advance.component';






@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CpProjectsComponent,
    MainLayoutComponent,
    CpPaymentsComponent,
    CpLadderComponent,
    CpAppComponent,
    CpReportsComponent,
    LoaderComponent,
    AdvanceComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ImageViewerModule,
    ModalModule,
    ToastrModule.forRoot({
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    }),
    CarouselModule,
    FormsModule,
    HttpClientModule,
   NgxDatatableModule,
    ModalModule.forRoot(),
    NgxLoadingModule.forRoot({
      backdropBackgroundColour: "rgba(0,0,0,0.5)",
      animationType: ngxLoadingAnimationTypes.circle,
      primaryColour: '#dd0031',
      secondaryColour: '#1976d2',
      backdropBorderRadius: '3px'
    })
  ],
  providers: [
    ToastrService,
    CommonService,
    LoaderService,
    DataSharingService,
    FileService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
