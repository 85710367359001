import { Component } from '@angular/core';
import { Router } from '@angular/router';
import IdleTimer from './IdleTimer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'hoabl-cp';
  timer;

  constructor(private router: Router) {}

  ngOnInit() {
    this.timer = new IdleTimer({
      timeout: 10, //expired after 10 secs
      onTimeout: () => {
        this.title = "Timeout";
        sessionStorage.removeItem('sessionData');
        this.router.navigateByUrl("/login")
       
      
      }
    });
  }
  ngOnDestroy() {
    this.timer.clear();
  }


}
