import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as FileSaver from 'file-saver';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataSharingService } from './commom.service';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    private httpHeaders = new HttpHeaders({'Content-Type': 'application/json' });
    private fileHeaders = new HttpHeaders({ 'Accept': 'application/json', 'Authorization': `Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI2MjhmMTRlNWM5ZTc3YzAwMDFiZGU5MTAiLCJzdWIiOiJIT0FCTCIsImlzcyI6IjBINTdMNWdGa0pIem1HZHB0Tnp2MjN5Skd6MWMyQ0FaIn0.3OjqtjAGwDl0AwvlhsO3ZX5ftV9HJmS2bnFRXOJds4Y`});
  
    constructor(
      private router: Router,
      private httpClient: HttpClient,
      private sanitizer: DomSanitizer,
      private envData: DataSharingService

    ) { }
    upload(formData: FormData): Observable<any> {
      const subject = new Subject<any>();
      const url = `${environment.rootUrl  + this.envData.fileUploadUrl}`;
      if (formData) {
        this.httpClient.post<any>(
          url,
          formData,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        ).subscribe(
          (response: HttpResponse<any>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
  
            subject.error(err);
          }
        );
      } else {
        subject.error('File is null or empty');
      }
  
      return subject.asObservable();
    }
  
    download(filePath: string, getAsFileUrl?: boolean): Observable<any> {
      const subject = new Subject<any>();
      filePath = filePath.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      const url = `${environment.rootUrl}${filePath}`;
  
      this.httpClient.get<any>(
        url,
        {
          headers: this.fileHeaders,
          observe: 'response',
          reportProgress: true,
          responseType: "blob" as "json"
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            if (getAsFileUrl) {
              const reader = new FileReader();
              reader.addEventListener("load", () => {
                subject.next(reader.result);
              }, false);
  
              reader.readAsDataURL(response.body);
            } else {
              let urlParts = filePath.split("/");
              let fileName = urlParts && urlParts.length > 0 && urlParts[urlParts.length - 1] ? urlParts[urlParts.length - 1] : 'temp';
              FileSaver.saveAs(response.body, fileName);
            }
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error
        }
      );
  
      return subject.asObservable();
    }
  
    delete(fileUrl: string,viemoId?:string): Observable<any> {
      
      const subject = new Subject<any>();
      
      if (fileUrl.startsWith("/api/downloadData")) {
        fileUrl = fileUrl.replace("/api/downloadData", "");
      }
      let url = null;
  
      if(viemoId && viemoId.trim().length && viemoId!=undefined && viemoId!=null){
        url = `${environment.rootUrl + this.envData.interfaceService}${this.envData.deleteDataFile}${fileUrl}"/"${viemoId}`;
      }
      else{
        url = `${environment.rootUrl + this.envData.interfaceService}${this.envData.deleteDataFile}${fileUrl}`;
      }
      
      this.httpClient.delete<any>(
        url,
        {
          observe: 'response',
          reportProgress: true,
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body);
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error
        }
      );
  
      return subject.asObservable();
    }
  
    view(filePath: string, getAsFileUrl?: boolean): Observable<any> {
      const subject = new Subject<any>();
      const url = `${environment.rootUrl }${filePath}`;
  
      this.httpClient.get<any>(
        url,
        { headers : {"Authorization":"eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI2MjhmMTRlNWM5ZTc3YzAwMDFiZGU5MTAiLCJzdWIiOiJIT0FCTCIsImlzcyI6IjBINTdMNWdGa0pIem1HZHB0Tnp2MjN5Skd6MWMyQ0FaIn0.3OjqtjAGwDl0AwvlhsO3ZX5ftV9HJmS2bnFRXOJds4Y","Content-Type":"application/json"},
          observe: 'response',
          reportProgress: true,
          responseType: "blob" as "json"
        }
      ).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body) {
            subject.next(response.body)
          }
        },
        (err: HttpErrorResponse) => {
          // All errors are handled in ErrorInterceptor, no further handling required
          // Unless any specific action is to be taken on some error
        }
      );
  
      return subject.asObservable();
    }
    
    getAWSCreds(requestBody: any): Observable<any> {
      //requestBody.clearCache = true;
      const subject = new Subject<any>();
      const url = `${environment.rootUrl + this.envData.interfaceService}` + "/api/getcredsforfileop";
        this.httpClient.post<any>(
          url,
          requestBody,
          {
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        ).subscribe(
          (response: HttpResponse<any>) => {
            if (response.body) {
              subject.next(response.body);
            }
          },
          (err: HttpErrorResponse) => {
            // All errors are handled in ErrorInterceptor, no further handling required
            // Unless any specific action is to be taken on some error
  
            subject.error(err);
          }
        );
      
  
      return subject.asObservable();
    }
 
  }
  