import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { NgxDatatableComponent } from '@tusharghoshbd/ngx-datatable'; 
import { CommonService, DataSharingService } from '../services/commom.service';
import { FileService } from '../services/file.service';
import { v4 as uuid } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import * as XLSX from 'xlsx';
import { createButtonConfig, ImageViewerConfig, IMAGEVIEWER_CONFIG } from '@hallysonh/ngx-imageviewer';
import { TouchSequence } from 'selenium-webdriver';
import { Route, Router } from '@angular/router';

const MY_IMAGEVIEWER_CONFIG: ImageViewerConfig = {
  
  zoomOutButton: { // zoomOut button config
    
    show: false // used to show/hide the button
  },
  nextPageButton: {
    show:false
  },
  zoomInButton: {
    show:false
  },
  rotateLeftButton: {
    show:false
  },
  rotateRightButton: {
    show:false
  },
  resetButton: {
    show:false
  },
  beforePageButton: {
    show:false
  },
  buttonStyle: {
    bgStyle: "#ffff"
  }
 
  
};
@Component({
  selector: 'app-cp-app',
  templateUrl: './cp-app.component.html',
  styleUrls: ['./cp-app.component.css'],
  providers: [
    {
      provide: IMAGEVIEWER_CONFIG,
      useValue: MY_IMAGEVIEWER_CONFIG
    }
  ]
})
export class CpAppComponent implements OnInit {
  
  activeProjects:any[];
  invoiceStatus = "Not Applicable";
paymentStatus = "Not Applicable";
remarkStatus = "";
appDoc : any;
downloadButtonFlag = false;
invoiceUploadFlag = false;
  showBtn: boolean = false;
  displayHeader  = "Earnings"
  rowData;
  private _canvasDim = { width: 300, height: 600 };
  @ViewChild('viewerwrapper', { static: false }) viewerwrapper: ElementRef;
  get canvasDim() {
    return this._canvasDim;
  }
  pdfSrc;
  docsArr = [];
  fileLength;
  selectedProject:any;
  xrCode:any;
  @ViewChild(NgxDatatableComponent, { static: false }) table: NgxDatatableComponent; 
  @ViewChild('actionTpl', { static: true }) actionTpl: TemplateRef<any>; 
  @ViewChild('detailsTpl', { static: true }) detailsTpl: TemplateRef<any>;
  @ViewChild('invoiceStatusButton', { static: true }) invoiceStatusButton: TemplateRef<any>;
 /*  @ViewChild('paymentTpl', { static: true }) paymentTpl: TemplateRef<any>;
  @ViewChild('rowDetailTpl', { static: true }) rowDetailTpl: ElementRef<any>; */

  options = {
    rowClickEvent: true,
    rowPerPageMenu: [5, 10, 20, 30],
    rowPerPage: 30,
    rowDetailTemplate: null
    
  }

// -------------- REPLACE
projectFilter = "All Projects";
launchFilter = "All Launches";
projectListforCp=[];
launchesListforCp = []
totalNetDealsInvoiceAmount : any;
totalNetDealsInvoiceAmountPaid : any;
invoiceRaised : any;
invoicePaymentStatus : any;
totalApplicationInvoiceAmount : any
paidInvoiceTillDate : any;
currentResultsHeader = [
]
currentResults=[];
invoiceProcessing : any;
partialPaid : any;
fullyPaid : any;
imageRef: BsModalRef;
totalEntityCount : any;
balanceAmount : any;
invoiceProcessingAmount : any;
showView: boolean = false;
showAdvance:boolean;
// ----------------------

  constructor(
    private modalService: BsModalService,
    private renderer: Renderer2,
    private route: Router,
    private dataSharingService: DataSharingService, private cd: ChangeDetectorRef, private toasterService: ToastrService, private commonService:CommonService, private fileService: FileService
  ) { }

  ngOnInit(): void {
    // console.log(this.actionTpl)
    this.activeProjects = this.dataSharingService.getProjects();
    this.xrCode = this.dataSharingService.getCpDetails()['xrCode'];
    
    if(this.dataSharingService.getCpDetails()['advancedPaymentsEligibility'] === 'No') {
      this.showAdvance = false;
    }
    else {
      this.showAdvance = true;
    }
    this.currentResultsHeader = []
    this.getApplicationIncentive_copy('project');
    
  }
 
 

  
  onRowClick(event){
    
  }

  getApplications(event){
    this.showView = true; 

   this.projectFilter = event.projectName;
   this.launchFilter = event.launchName;
    this.getApplicationIncentive('project') 
   console.log(event)
   
  /*  let element = document.getElementById('excel-table'); */
 
  /*  for (var i = 0; i < p.length; i++) {
     p[i].style.backgroundColor = "green";
 }  */
  
 }
  stats:any;
  getApplicationIncentive(filterType){
    let payload = {};
    payload["xrCode"] = this.xrCode;
    // if(filterType=='project'){
    //   this.launchFilter = "All Launches";
    // }
    if(this.projectFilter!="All Projects" && this.projectFilter !=''){
        payload["project"] = this.projectFilter;
        if(this.launchFilter!="All Launches" && this.launchFilter !=''){
          payload["launch"] = this.launchFilter;
        }
    }
    if(this.projectFilter=="All Projects" && this.launchFilter=="All Launches"){
     
      console.log("Inside if::::::::::::::::::::::::::::::::::::")
      this.getFetchDoc();
      this.getApplicationIncentive_copy(filterType)
    }
    else {
    this.commonService.getApplicationIncentive(payload).subscribe(
        (response)=>{
          if (response.success) {
            this.currentResultsHeader = response['payload']['headerList']
           

            const temp = this.currentResultsHeader.pop();
            this.currentResultsHeader.unshift(temp);
           
            this.currentResultsHeader.forEach((element) => {
            
              if(element.title === 'View Details') {
                element.cellTemplate = this.detailsTpl
                element.width = 100
                console.log(this.detailsTpl)
              }
             
              
            })
            if(this.projectFilter!="All Projects" && this.projectFilter !=''){
              this.currentResults = response['payload']['applications'];
          }
          else {
            this.currentResults = response['payload']['applications'];
            

          }

          for (let i = 0; i < this.currentResults.length ; i++){

            if(this.currentResults[i].applicationDate){
              var d = new Date(this.currentResults[i].applicationDate);
              var stringDate = (d.getDate() + '-' + (d.getMonth()+1) + '-' + d.getFullYear());
              this.currentResults[i].applicationDate = stringDate
            }
          }

          
          console.log(this.currentResults)
            this.stats=response.payload;
            if(this.projectFilter =="All Projects"){
              this.projectListforCp = response.payload.projects;
            }
            if(this.launchFilter =="All Launches"){
              this.launchesListforCp = response.payload.launches;
            }
            this.totalApplicationInvoiceAmount = Math.ceil(response['payload']['totalApplicationInvoiceAmount']);
            this.paidInvoiceTillDate = Math.ceil(response['payload']['paidInvoiceTillDate']);
            this.invoiceRaised = Math.ceil(response['payload']['invoiceRaised']);
            this.invoicePaymentStatus = response['payload']['invoicePaymentStatus'];
            this.invoiceProcessing = response['payload']['invoiceProcessing'];
            this.partialPaid = response['payload']['partialPaid'];
            this.fullyPaid = response['payload']['fullyPaid'];
            this.invoiceUploadFlag = response['payload']['invoiceUploadFlag'];
            this.totalEntityCount = response['payload']["totalCount"];
            this.balanceAmount = Math.ceil(response['payload']["balanceAmount"]);
            this.invoiceProcessingAmount = Math.ceil(response['payload']["invoiceProcessingAmount"]);
          }
        },
        (error)=>{},
      );

  }
}
 viewPlot() {

 }

  showTable:boolean = false;
  getApplicationIncentive_copy(filterType){

    if(filterType=='project'){
      this.launchFilter = "All Launches";
    }
    
    let payload = {};
    payload["xrCode"] = this.xrCode;
    console.log(this.projectFilter);
    if(this.projectFilter!="All Projects" && this.projectFilter !=''){
        payload["project"] = this.projectFilter;
        if(this.launchFilter!="All Launches" && this.launchFilter !=''){
          payload["launch"] = this.launchFilter;
        }
        
    }

    if(this.projectFilter!="All Projects" && this.projectFilter !='' && this.launchFilter!="All Launches" && this.launchFilter !=''){
     
      this.getFetchDoc();
  }
    
    this.commonService.getApplicationIncentive_copy(payload).subscribe(
        (response)=>{
          if (response.success) {
            this.currentResultsHeader = response['payload']['headerList']
            console.log("current", this.currentResultsHeader)
            this.currentResultsHeader.forEach((element) => {

              if(element.title === 'Invoice Document') {
                console.log("flag:::::::",this.invoiceStatusButton)
                element.cellTemplate = this.invoiceStatusButton
                element.width = 100
                console.log(this.invoiceStatusButton)
              }
              
              
              if(element.title === 'View Details') {
              
                element.cellTemplate = this.detailsTpl
                element.width = 100
                console.log(this.detailsTpl)
                
              }
              
            })
            this.showTable =true
            this.currentResults = response['payload']['applications'];
            this.stats=response.payload;
            if(this.projectFilter =="All Projects"){
              this.projectListforCp = response.payload.projects;
            }
            if(this.launchFilter =="All Launches"){
              this.launchesListforCp = response.payload.launches;
            }
            this.totalApplicationInvoiceAmount = Math.ceil(response['payload']['totalApplicationInvoiceAmount']);
            this.paidInvoiceTillDate = Math.ceil(response['payload']['paidInvoiceTillDate']);
            this.invoiceRaised = Math.ceil(response['payload']['invoiceRaised']);
            this.invoicePaymentStatus = response['payload']['invoicePaymentStatus'];
            this.invoiceProcessing = response['payload']['invoiceProcessing'];
            this.partialPaid = response['payload']['partialPaid'];
            this.fullyPaid = response['payload']['fullyPaid'];
            this.invoiceUploadFlag = response['payload']['invoiceUploadFlag'];
            this.totalEntityCount = response['payload']["totalCount"];
            this.balanceAmount = Math.ceil(response['payload']["balanceAmount"]);
            this.invoiceProcessingAmount = Math.ceil(response['payload']["invoiceProcessingAmount"]);
          }
        },
        (error)=>{},
      );

  }
  
  viewImage(content: TemplateRef<any>, rowItem) {
    this.rowData = rowItem
   
    if(this.rowData.invoiceDocument)
    {
    this.getDownloadFileObjectUrl(this.rowData.invoiceDocument)
    this.imageRef = this.modalService.show(content)
    }
    else {
      this.toasterService.error("Please Upload File to preview")
    }
  
  }


  getDownloadFileObjectUrl(doc) {
    console.log(doc)
    
        this.fileService.download(doc.downloadFileUrl, true).subscribe(
          objectUrl => {
            if (objectUrl) {
              var type = (objectUrl.split(';')[0]).replace("data:","");
              fetch(objectUrl)
              .then(res => res.blob())
              .then(blob => {
                const file = new File([blob], doc.fileName,{ type: type })
                this.pdfSrc = (file);
              })
              // this.downloadFileObjectUrl = objectUrl;
            }
          }
        );
      }

      onDocumentUpload(event,rowType) {
        // console.log(rowType);
        
        
       this.fileLength = event.target.files.length
       this.docsArr = []
        for (let index = 0; index < event.target.files.length; index++) {
          let uploadedFile = event.target.files[index];
          const fileInputForm = new FormData();
          const file: File = uploadedFile
          fileInputForm.append('file', file, file.name);
          var uploadFileName = uuid(); 
          fileInputForm.append("fileName", file.name);
          fileInputForm.append("functionInstanceName", "Activity");
          fileInputForm.append("entityType", "ACTIVITY_" + 'APPLICATION_DOCUMENT');
          fileInputForm.append("entityRef", uploadFileName);  
          this.uploadFile(fileInputForm,rowType);
        }
      
    
      }
    
  changeRoute(item:any) {
    this.route.navigateByUrl("/"+item)
  }
  uploadFile(formData: FormData,rowType) {
    this.fileService.upload(formData)
      .subscribe(
        response => {
          if (response && response["url"] && response["fileName"]) {
            
              let doc: any = {};
              doc.url = response["url"];
              doc.fileName = response["fileName"];
              doc.downloadFileUrl = response["downloadFileUrl"];
              doc.fullDataUrl = response["fullDataUrl"];
              doc.fullFileUrl = response["fullFileUrl"];
              this.docsArr.push(doc)
              this.submitDoc(rowType)

            }
            
          })
          
        };
        submitDoc(rowType) {
          if(this.fileLength == this.docsArr.length) {
        
            let appDocPayload = {}
            if(rowType.appDoc){
              appDocPayload = rowType.appDoc;
            }
            appDocPayload['xrCode'] = this.xrCode;
            appDocPayload['project'] = rowType.projectName;
            appDocPayload['launch'] = rowType.launchName;
            appDocPayload['invoiceStatus'] = "Invoice Uploaded";
            appDocPayload['invoiceDocuments'] =  this.docsArr[0];
            let payload = {}
            payload['appDoc'] = appDocPayload;
        
            this.commonService.updateAppDeals(payload).subscribe((data) => {
              if(data && data.payload) {
                this.toasterService.success("File Uploaded Successfully")
                this.fileLength = 0;
                this.invoiceStatus = data.payload.appDoc["invoiceStatus"]
                this.paymentStatus = data.payload.appDoc["paymentStatus"]
                this.remarkStatus = data.payload.appDoc["remark"]
                this.appDoc = data.payload.appDoc;
                if(this.appDoc.invoiceDocuments && this.appDoc.invoiceDocuments.downloadFileUrl){
                  this.downloadButtonFlag = true;
                }
              }
            })
        
          }
        
        }
        closeApplications(){
          this.showView = false
          this.getApplicationIncentive_copy("project")
          }
getFetchDoc() {
  let payload = 
    {
      "xrCode": this.xrCode,
      "project": this.projectFilter,
      "launch": this.launchFilter
      }
  this.commonService.fetchDeals(payload).subscribe((data) => {
    if(data && data.payload && data.payload.appDoc) {
      this.invoiceStatus = data.payload.appDoc["invoiceStatus"]
      this.paymentStatus = data.payload.appDoc["paymentStatus"]
      this.remarkStatus = data.payload.appDoc["remark"]
      this.appDoc = data.payload.appDoc;
      if(this.appDoc.invoiceDocuments && this.appDoc.invoiceDocuments.downloadFileUrl){
        this.downloadButtonFlag = true;
      }
    }
    else{
      this.invoiceStatus = "Not Applicable";
      this.paymentStatus = "Not Applicable";
      this.remarkStatus = "";
    }
  })
}
downloadFile(){
  let fileName = this.rowData.invoiceDocument.downloadFileUrl;
 
  console.log(fileName);
  this.fileService.download(fileName).subscribe((data) => {

  })
}
exportFile() {
    
  let element = document.getElementById('excel-table');
  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */  
  XLSX.writeFile(wb, this.displayHeader+".xlsx");

}
downloadInvoiceFile(){
  
  this.fileService.download("/api/downloadDataFile/FILEREADER_TEST/FILEREADER_TEST/Test/null/Channel Partner - Invoice Format.xlsm").subscribe((data) => {

  })
}
downloadRow(row) {
  let fileName = row.invoiceDocument.downloadFileUrl;
  console.log(fileName);
  this.fileService.download(fileName).subscribe((data) => {

  })
  
}

       
  
 }
