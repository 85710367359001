import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  loaded: boolean;
  

  constructor(private loaderService: LoaderService) { 
    this.loaderService.isLoading.subscribe((data) => { 
       this.loaded = data
      //  console.log(this.loaded)

    })
  }

  ngOnInit(): void {
  }

}
