import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { NgxDatatableComponent } from '@tusharghoshbd/ngx-datatable'; 
import { CommonService, DataSharingService } from '../services/commom.service';
import { FileService } from '../services/file.service';
import { v4 as uuid } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import * as XLSX from 'xlsx';
import { createButtonConfig, ImageViewerConfig, IMAGEVIEWER_CONFIG } from '@hallysonh/ngx-imageviewer';
import { TouchSequence } from 'selenium-webdriver';
import { Route, Router } from '@angular/router';

const MY_IMAGEVIEWER_CONFIG: ImageViewerConfig = {
  
  zoomOutButton: { // zoomOut button config
    
    show: false // used to show/hide the button
  },
  nextPageButton: {
    show:false
  },
  zoomInButton: {
    show:false
  },
  rotateLeftButton: {
    show:false
  },
  rotateRightButton: {
    show:false
  },
  resetButton: {
    show:false
  },
  beforePageButton: {
    show:false
  },
  buttonStyle: {
    bgStyle: "#ffff"
  }
 
  
};
@Component({
  selector: 'app-cp-payments',
  templateUrl: './cp-payments.component.html',
  styleUrls: ['./cp-payments.component.css'],
  providers: [
    {
      provide: IMAGEVIEWER_CONFIG,
      useValue: MY_IMAGEVIEWER_CONFIG
    }
  ]
})
export class CpPaymentsComponent implements OnInit {
  
  activeProjects:any[];
  showBtn: boolean = false;
  displayHeader  = "Earnings"
  rowData;
  private _canvasDim = { width: 300, height: 600 };
  @ViewChild('viewerwrapper', { static: false }) viewerwrapper: ElementRef;
  get canvasDim() {
    return this._canvasDim;
  }
  pdfSrc;
  docsArr = [];
  fileLength;
  selectedProject:any;
  xrCode:any;
  @ViewChild(NgxDatatableComponent, { static: false }) table: NgxDatatableComponent; 
  @ViewChild('actionTpl', { static: true }) actionTpl: TemplateRef<any>;
  @ViewChild('detailsTpl', { static: true }) detailsTpl: TemplateRef<any>;
  @ViewChild('paymentTpl', { static: true }) paymentTpl: TemplateRef<any>;
  @ViewChild('rowDetailTpl', { static: true }) rowDetailTpl: ElementRef<any>;

  options = {
    rowClickEvent: true,
    rowPerPageMenu: [5, 10, 20, 30],
    rowPerPage: 30,
    rowDetailTemplate: null
    
  }

// -------------- REPLACE
projectFilter = "All Projects";
launchFilter = "All Launches";
projectListforCp=[];
launchesListforCp = []
totalNetDealsInvoiceAmount : any;
totalNetDealsInvoiceAmountPaid : any;
invoiceRaised : any;
invoicePaymentStatus : any;
currentResultsHeader = [
]
currentResults=[];
invoiceProcessing : any;
partialPaid : any;
fullyPaid : any;
imageRef: BsModalRef;
totalEntityCount : any;
balanceAmount : any;
invoiceProcessingAmount : any;
showView: boolean = false;
showAdvance:boolean;
// ----------------------

  constructor(
    private modalService: BsModalService,
    private renderer: Renderer2,
    private route: Router,
    private dataSharingService: DataSharingService, private cd: ChangeDetectorRef, private toasterService: ToastrService, private commonService:CommonService, private fileService: FileService
  ) { }

  ngOnInit(): void {
    // console.log(this.actionTpl)
    this.activeProjects = this.dataSharingService.getProjects();
    this.xrCode = this.dataSharingService.getCpDetails()['xrCode'];
    
    if(this.dataSharingService.getCpDetails()['advancedPaymentsEligibility'] === 'No') {
      this.showAdvance = false;
    }
    else {
      this.showAdvance = true;
    }
    this.getBrokerageIncentive('project');
    
  }
 
  changeRoute(item:any) {
    this.route.navigateByUrl("/"+item)
  }

  
  onRowClick(event){
    
   /*  let element = document.getElementById('excel-table'); */
  
   /*  for (var i = 0; i < p.length; i++) {
      p[i].style.backgroundColor = "green";
  }  */
   
  }
  stats:any;
  getBrokerageIncentive(filterType){
    let payload = {};
    payload["xrCode"] = this.xrCode;
    if(filterType=='project'){
      this.launchFilter = "All Launches";
    }
    if(this.projectFilter!="All Projects" && this.projectFilter !=''){
        payload["project"] = this.projectFilter;
        if(this.launchFilter!="All Launches" && this.launchFilter !=''){
          payload["launch"] = this.launchFilter;
        }
    }
    this.commonService.getBrokerageIncentive(payload).subscribe(
        (response)=>{
          if (response.success) {
            this.currentResultsHeader = response['payload']['headerList']
           

           

            const temp = this.currentResultsHeader.pop();
            this.currentResultsHeader.unshift(temp);
           
            this.currentResultsHeader.forEach((element) => {
              if(element.title === 'Invoice Document') {
                element.cellTemplate = this.actionTpl;
              }
              if(element.title === 'View Details') {
                element.cellTemplate = this.detailsTpl
                element.width = 100
                console.log(this.detailsTpl)
              }
              if(element.title === 'Payment Status') {
                element.cellTemplate = this.paymentTpl
                element.width = 130
              }
              
            })
            if(this.projectFilter!="All Projects" && this.projectFilter !=''){
              this.currentResults = response['payload']['netDeals'];
          }
          else {
            this.currentResults = response['payload']['netDeals'];
            

          }

          this.currentResults.forEach((element) => {

            if (element.netAmount){
              element.netAmount = Math.round(element.netAmount)
            }
            if (element.totalAmount){
              element.totalAmount = Math.round(element.totalAmount)
            }
            if (element.tdsAmount){
              element.tdsAmount = Math.round(element.tdsAmount)
            }
            if (element.invoiceAmount){
              element.invoiceAmount = Math.round(element.invoiceAmount)
            }
            if (element.eligibilityAmount){
              element.eligibilityAmount = Math.round(element.eligibilityAmount)
            }


      
            
          })


          console.log(this.currentResults)
            this.stats=response.payload;
            if(this.projectFilter =="All Projects"){
              this.projectListforCp = response.payload.projects;
            }
            if(this.launchFilter =="All Launches"){
              this.launchesListforCp = response.payload.launches;
            }
            this.totalNetDealsInvoiceAmount = Math.round(response['payload']['totalNetDealsInvoiceAmount']);
            this.totalNetDealsInvoiceAmountPaid = Math.ceil(response['payload']['totalNetDealsInvoiceAmountPaid']);
            this.invoiceRaised = Math.round(response['payload']['invoiceRaised']);
            this.invoicePaymentStatus = response['payload']['invoicePaymentStatus'];
            this.invoiceProcessing = response['payload']['invoiceProcessing'];
            this.partialPaid = response['payload']['partialPaid'];
            this.fullyPaid = response['payload']['fullyPaid'];
            this.totalEntityCount = response['payload']["totalCount"];
            this.balanceAmount = Math.round(response['payload']["balanceAmount"]);
            this.invoiceProcessingAmount = response['payload']["invoiceProcessingAmount"];
          }
        },
        (error)=>{},
      );

  }
  viewPlot(actionType, plotNumber?) {
   
    if(actionType === 'view')
    {
    this.showView = !this.showView
    let payload = {};
    payload["uniquePlotNumber"] = plotNumber;
    
    this.commonService.viewBrokerageIncentive(payload).subscribe(
      (response)=>{
        if (response.success) {
          this.currentResultsHeader = response['payload']['headerList']
          const temp = this.currentResultsHeader[this.currentResultsHeader.length - 2];
          this.currentResultsHeader.splice(this.currentResultsHeader.length-2,1)
          this.currentResultsHeader.unshift(temp);
          this.currentResultsHeader.forEach((element) => {
            if(element.title === 'Invoice Document') {
              element.cellTemplate = this.actionTpl;
            }
            if(element.title === 'View Details') {
              element.cellTemplate = this.detailsTpl
              element.width = 167
              
            }
          
          })
          this.currentResults = response['payload']['netDeals']
          this.currentResults.forEach((element) => {

            if (element.netAmount){
              element.netAmount = Math.round(element.netAmount)
            }
            if (element.totalAmount){
              element.totalAmount = Math.round(element.totalAmount)
            }
            if (element.tdsAmount){
              element.tdsAmount = Math.round(element.tdsAmount)
            }
            if (element.invoiceAmount){
              element.invoiceAmount = Math.round(element.invoiceAmount)
            }
            if (element.eligibilityAmount){
              element.eligibilityAmount = Math.round(element.eligibilityAmount)
            }
      
            
            
            
          })

        }
      },
      (error)=>{},
    );
   /*  let element = document.getElementById('randomId')
    console.log(element)
    let p = element.getElementsByClassName('cus-cursor-pointer')[0]
    let t = p.childNodes
    console.log(t)
    for (let index = 0; index < t.length; index++) {
      console.log(t[index])
      
    }
    
    
    p.setAttribute("style", "background-color:red !important;") */

    }
    else {
      this.showView = !this.showView
      this.getBrokerageIncentive('project');
    }
   

  }
  
  onDocumentUpload(event, rowType) {
    // console.log(rowType);
    let eligibilityStatus = rowType['eligibilityStatus'];
    let invoiceStatus = rowType['invoiceStatus'];
    if((!invoiceStatus && eligibilityStatus=="Yes") || invoiceStatus=="Rejected"){
   this.fileLength = event.target.files.length
   this.docsArr = []
    for (let index = 0; index < event.target.files.length; index++) {
      let uploadedFile = event.target.files[index];
      const fileInputForm = new FormData();
      const file: File = uploadedFile
      fileInputForm.append('file', file, file.name);
      var uploadFileName = uuid(); 
      fileInputForm.append("fileName", file.name);
      fileInputForm.append("functionInstanceName", "Activity");
      fileInputForm.append("entityType", "ACTIVITY_" + rowType._id);
      fileInputForm.append("entityRef", uploadFileName);  
      this.uploadFile(fileInputForm, rowType);
    }
  }
  else{
    if(invoiceStatus=="Invoice Uploaded" || invoiceStatus=="Accepted"){
      this.toasterService.error("Invoice has already been uploaded.")
    }
    else {
    this.toasterService.error("Not eligible to upload the file.")
    }
  }

  }
  uploadFile(formData: FormData, rowType) {
    this.fileService.upload(formData)
      .subscribe(
        response => {
          if (response && response["url"] && response["fileName"]) {
            
              let doc: any = {};
              doc.url = response["url"];
              doc.fileName = response["fileName"];
              doc.downloadFileUrl = response["downloadFileUrl"];
              doc.fullDataUrl = response["fullDataUrl"];
              doc.fullFileUrl = response["fullFileUrl"];
              this.docsArr.push(doc)
              this.submitDoc(rowType)
              
             
              
            
            }
            
          })
          
        };

submitDoc(rowType) {
  if(this.fileLength == this.docsArr.length) {
    rowType["invoiceDocuments"] = this.docsArr[0];
    rowType['invoiceStatus'] = "Invoice Uploaded";
    if("_autoId" in rowType) {
      delete rowType._autoId
    }
    console.log("rowType", rowType)
    let payload = {
      "netDeal": rowType
    }
    this.commonService.updateNetDeals(payload).subscribe((data) => {
      if(data && data.payload) {
        this.toasterService.success("File Uploaded Successfully")
        this.fileLength = 0;
        this.getBrokerageIncentive('project');
    
      }
    })

  }

}
getStatus() {
  this.showBtn = true;
  let payload = 
    {

      "xrCode": this.xrCode
  
  }
  
  this.commonService.getEligible(payload).subscribe((response) => {
    if (response.success) {
      this.currentResultsHeader = response['payload']['headerList']

      const temp = this.currentResultsHeader.pop();
      this.currentResultsHeader.unshift(temp);
      
      this.currentResultsHeader.forEach((element) => {
        if(element.title === 'Invoice Document') {
          element.cellTemplate = this.actionTpl;
        }
        if(element.title === 'View Details') {
          element.cellTemplate = this.detailsTpl
          element.width = 167
      
        }
        if(element.title === 'Payment Status') {
          element.cellTemplate = this.paymentTpl
          element.width = 130
        }
        
      })

     
     
    
      if(this.projectFilter!="All Projects" && this.projectFilter !=''){
        this.currentResults = response['payload']['netDeals'];
    }
    else {
      this.currentResults = response['payload']['netDeals'];
      console.log(this.currentResults)

    }

    this.currentResults.forEach((element) => {

      if (element.netAmount){
        element.netAmount = Math.round(element.netAmount)
      }
      if (element.totalAmount){
        element.totalAmount = Math.round(element.totalAmount)
      }
      if (element.tdsAmount){
        element.tdsAmount = Math.round(element.tdsAmount)
      }
      if (element.invoiceAmount){
        element.invoiceAmount = Math.round(element.invoiceAmount)
      }
      if (element.eligibilityAmount){
        element.eligibilityAmount = Math.round(element.eligibilityAmount)
      }



      
    })
     
      this.stats=response.payload;
      if(this.projectFilter =="All Projects"){
        this.projectListforCp = response.payload.projects;
      }
      if(this.launchFilter =="All Launches"){
        this.launchesListforCp = response.payload.launches;
      }
      this.totalNetDealsInvoiceAmount = Math.ceil(response['payload']['totalNetDealsInvoiceAmount']);
      this.totalNetDealsInvoiceAmountPaid = Math.ceil(response['payload']['totalNetDealsInvoiceAmountPaid']);
      this.invoiceRaised = Math.ceil(response['payload']['invoiceRaised']);
      this.invoicePaymentStatus = response['payload']['invoicePaymentStatus'];
      this.invoiceProcessing = response['payload']['invoiceProcessing'];
      this.partialPaid = response['payload']['partialPaid'];
      this.fullyPaid = response['payload']['fullyPaid'];
      this.totalEntityCount = response['payload']["totalCount"];
      this.balanceAmount = response['payload']["balanceAmount"];
      this.invoiceProcessingAmount = response['payload']["invoiceProcessingAmount"];
    }
    
  })
}
downloadFile(){
  // console.log(row);
  let fileName = this.rowData.invoiceDocuments.downloadFileUrl;
  console.log(fileName);
  this.fileService.download(fileName).subscribe((data) => {

  })
}
viewImage(content: TemplateRef<any>, rowItem) {
  this.rowData = rowItem
  if(this.rowData.invoiceDocuments)
  {
  this.getDownloadFileObjectUrl(this.rowData.invoiceDocuments)
  this.imageRef = this.modalService.show(content)
  }
  else {
    this.toasterService.error("Please Upload File to preview")
  }

}
getDownloadFileObjectUrl(doc) {
  console.log(doc)
  
      this.fileService.download(doc.downloadFileUrl, true).subscribe(
        objectUrl => {
          if (objectUrl) {
            var type = (objectUrl.split(';')[0]).replace("data:","");
            fetch(objectUrl)
            .then(res => res.blob())
            .then(blob => {
              const file = new File([blob], doc.fileName,{ type: type })
              this.pdfSrc = (file);
            })
            // this.downloadFileObjectUrl = objectUrl;
          }
        }
      );
    }

    exportFile() {
    
      let element = document.getElementById('excel-table');
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
   
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
   
      /* save to file */  
      XLSX.writeFile(wb, this.displayHeader+".xlsx");
  
    }
  
    downloadInvoiceFile(){
  
      this.fileService.download("/api/downloadDataFile/FILEREADER_TEST/FILEREADER_TEST/Test/null/Channel Partner - Invoice Format.xlsm").subscribe((data) => {
    
      })
    }


       
  
 }
