import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,  Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
      if(sessionStorage.getItem('sessionData')=='ok'){
        return true;
      }else{
        return false;
        
      }
     
      // return true;
  }
  
}


@Injectable({
    providedIn: 'root'
  })
  export class AntiAuthGuard implements CanActivate {
  
    constructor(
      private router: Router,
      private universalUser: UniversalUser
    ) { }
  
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if(sessionStorage.getItem('sessionData')=='ok'){
        console.log("ok")
        this.router.navigateByUrl("projects"); 
        return false;
      }else{
        return true;
      }
    }
  }


  @Injectable({
    providedIn: 'root'
  })
  export class UniversalUser {
    private readonly X_AUTH_TOKEN_KEY: string = 'token';
    private xAuthToken: string;
    user ={};
    setXAuthToken(xAuthToken: string): void {
      if (xAuthToken && xAuthToken.trim().length > 0) {
        this.xAuthToken = xAuthToken;
        localStorage.setItem(this.X_AUTH_TOKEN_KEY, this.xAuthToken);
      }
    }
  
    getXAuthToken(): string {
      if (this.xAuthToken && this.xAuthToken.trim().length > 0) {
        return this.xAuthToken;
      } else if (localStorage.getItem(this.X_AUTH_TOKEN_KEY) && localStorage.getItem(this.X_AUTH_TOKEN_KEY).trim().length > 0) {
        this.xAuthToken = localStorage.getItem(this.X_AUTH_TOKEN_KEY);
        return this.xAuthToken;
      }
  
      return null;
    }
  
    setUser(user, shouldRedirect?: boolean) {
      window.localStorage.setItem('uninversalUser', JSON.stringify(user));
      this.user = user;
  
      if (shouldRedirect) {
        window.localStorage.setItem('sessionAvailable', new Date().getTime().toString());
      }
    }
    getUser() {
      if (!this.user) {
        this.user = JSON.parse(window.localStorage.getItem('uninversalUser'));
      }
      return this.user;
    }
  
    removeUser() {
      this.xAuthToken = null;
      localStorage.removeItem(this.X_AUTH_TOKEN_KEY);
  
      this.user = null;
      window.localStorage.removeItem('uninversalUser');
      window.localStorage.setItem('sessionExpired', new Date().getTime().toString());
    }
    setCompanyDetails(companyDetails?: any) {
      window.localStorage.removeItem('companyDetails');
      window.localStorage.setItem('companyDetails', JSON.stringify(companyDetails));
    }
  
    getCompanyDetails() {
      return JSON.parse(window.localStorage.getItem('companyDetails'));
    }
    removeCompany() {
      window.localStorage.removeItem('companyDetails');
    }
  }