import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import $ from "jquery";
import { CommonService, DataSharingService } from '../services/commom.service';
import { ToastrService } from 'ngx-toastr';
import { UniversalUser } from '../services/auth.guard';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    animateOut: 'fadeOut',
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };

  slidesStore= [
    {"_id":1,"title":"slide1","alt":"slide1","src":"./assets/images/bliss.jpg"},
    {"_id":2,"title":"slide2","alt":"slide2","src":"./assets/images/tomorrowland.jpg"},
    {"_id":3,"title":"slide2","alt":"slide3","src":"./assets/images/celebration.jpg"},
    {"_id":4,"title":"slide2","alt":"slide4","src":"./assets/images/maharaja.jpg"}
  ];

  mobileNo:"";
  otp:"";
  token;


  constructor(
    private router:Router,
    private commonService:CommonService,
    private dataSharingService: DataSharingService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private universalUser: UniversalUser
    
  ) { }

  ngOnInit(): void {
    /* this.route.queryParamMap.subscribe(params => {
      if (params.get('token')) {
        this.token = params.get('token');
      }
    });
    if (this.token) {
      
    }
    console.log(this.token) */
    // sessionStorage.setItem('sessionData',"ok") ;
    // if (sessionStorage.getItem('sessionData')=='ok') {
    //     this.router.navigateByUrl("/main");
    // }
  }
  onBlurEvent(item) {
    if((item).toString().length <= 10)
    {
    this.phonenumberVerify(item)
    }

  }
  search(item) {
    if( (item).toString().length === 10) {
     
      this.sendOtpBtn()
    }
    else {
      this.phonenumberVerify(item)

    }
  }
  sendOtpBtn(){
    let phoneCheck = this.phonenumberVerify(this.mobileNo);
    if (phoneCheck == true) {
      this.dataSharingService.setMobileNo(this.mobileNo)
      let payload = {
        "payload": {
          "project": "cpIntegration",
          "customerMobile": "+91-"+this.mobileNo,
          "type": "sendSms"
        }
      }; 
      this.commonService.sendOtp(payload).subscribe(
        (reponse)=>{
          if(reponse.success ==true){
            this.showAfterOTP();
          }
          else {
            this.toastrService.error(reponse.error)
          }
        },
        (error)=>{},
      );
    }
  }
  verifyOtpBtn(){
    let phoneCheck = this.phonenumberVerify(this.mobileNo);
    if (phoneCheck == true) {
      let payload = {
        "payload": {
          "project": "cpIntegration",
          "customerMobile": "+91-"+this.mobileNo,
          "type": "verify",
          "otp":this.otp
        }
      }; 
      this.commonService.verifyOtp(payload).subscribe(
        (reponse)=>{
          if(reponse.success ==true){
            sessionStorage.setItem('sessionData','ok');
            this.dataSharingService.mobileNo ="+91-"+this.mobileNo;
            this.router.navigateByUrl("projects");
          }
          else {
            this.toastrService.error("Invalid OTP")
          }
        },
        (error)=>{},
      );
    }
  }
 /*  OauthLogin() {
    this.commonService.authenticateOauth(this.token)
      .subscribe(
        user => {
          this.user = user;
          this.universalUser.setUser(user, true);
          this.universalUser.setXAuthToken(this.token);
          window.history.replaceState(null, null, window.location.origin);
          if (this.route.snapshot.queryParams[commonKeys.returnUrl]) {
            this.returnUrl = this.route.snapshot.queryParams[commonKeys.returnUrl];
          }
          else if (this.universalUser.displayBusinessDashboard()) {
            this.returnUrl = "/businessoutlook";
          }
          else {
            this.returnUrl = "/dashboard";
          }
          this.router.navigate([this.returnUrl]);
        },
        error => {

        }
      );
  } */
  phonenumberVerify(inputtxt) {
    var phoneno = /^\d{10}$/;
    if (inputtxt.match(phoneno)) {
      $("#mobError").removeClass("showDiv");
      return true;
    } else {
      $("#mobError").addClass("showDiv");
      return false;
    }
  }
  showAfterOTP() {
    $(".showAfterOTP").toggle('fast');
    $(".hideAfterOTP").toggle('fast');
  }
}
