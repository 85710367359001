import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdvanceComponent } from './advance/advance.component';
import { CpAppComponent } from './cp-app/cp-app.component';
import { CpLadderComponent } from './cp-ladder/cp-ladder.component';
import { CpPaymentsComponent } from './cp-payments/cp-payments.component';
import { CpProjectsComponent } from './cp-projects/cp-projects.component';
import { CpReportsComponent } from './cp-reports/cp-reports.component';
import { LoginComponent } from './login/login.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { AntiAuthGuard, AuthGuard } from './services/auth.guard';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    canActivate: [AntiAuthGuard],  
    component: LoginComponent
  },
  {
  path: '',
  canActivate: [AuthGuard],
  component: MainLayoutComponent,
    children: [
      { path: '', canActivate: [AuthGuard], redirectTo: 'projects', pathMatch: 'full' },
      { path: 'projects', canActivate: [AuthGuard], component: CpProjectsComponent },
      { path: 'payments', canActivate: [AuthGuard], component: CpPaymentsComponent },
      { path: 'reports', canActivate: [AuthGuard], component: CpReportsComponent },
      { path: 'ladder', canActivate: [AuthGuard], component: CpLadderComponent },
      { path: 'app', canActivate: [AuthGuard], component: CpAppComponent },
      { path: 'advance', canActivate: [AuthGuard], component: AdvanceComponent }
    ]
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }