import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
/* import { NgxDatatableComponent } from '@tusharghoshbd/ngx-datatable'; */
import { ToastrService } from 'ngx-toastr';
import { CommonService, DataSharingService } from '../services/commom.service';
import { FileService } from '../services/file.service';
import { v4 as uuid } from 'uuid';
import { ImageViewerConfig, IMAGEVIEWER_CONFIG } from '@hallysonh/ngx-imageviewer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';

const MY_IMAGEVIEWER_CONFIG: ImageViewerConfig = {
  
  zoomOutButton: { // zoomOut button config
    
    show: false // used to show/hide the button
  },
  nextPageButton: {
    show:false
  },
  zoomInButton: {
    show:false
  },
  rotateLeftButton: {
    show:false
  },
  rotateRightButton: {
    show:false
  },
  resetButton: {
    show:false
  },
  beforePageButton: {
    show:false
  },
  buttonStyle: {
    bgStyle: "#ffff"
  }
 
  
};
@Component({
  selector: 'app-cp-ladder',
  templateUrl: './cp-ladder.component.html',
  styleUrls: ['./cp-ladder.component.css'],
  providers: [
    {
      provide: IMAGEVIEWER_CONFIG,
      useValue: MY_IMAGEVIEWER_CONFIG
    }
  ]
})
export class CpLadderComponent implements OnInit {
  activeProjects:any[];
  showView: boolean = false;
  selectedProject:any;
  displayHeader  = "Ladders"
  xrCode:any;
  docsArr = [];
  fileLength;
  rowData;
  pdfSrc;
  imageRef: BsModalRef;
  /* @ViewChild(NgxDatatableComponent, { static: false }) table: NgxDatatableComponent; */
  @ViewChild('actionTpl', { static: true }) actionTpl: TemplateRef<any>;
  @ViewChild('detailsTpl', { static: true }) detailsTpl: TemplateRef<any>;
  @ViewChild('paymentTpl', { static: true }) paymentTpl: TemplateRef<any>;
  options = {
    rowClickEvent: true,
    rowPerPageMenu: [5, 10, 20, 30],
    rowPerPage: 30
  }

// -------------- REPLACE
projectFilter = "All Projects";
launchFilter = "All Launches";
projectListforCp=[];
launchesListforCp = []
currentResultsHeader = [
]
currentResults=[];
totalCPLadderInvoiceAmount : any;
totalCpLadderInvoiceAmountPaid : any;
invoiceRaised : any;
invoicePaymentStatus : any;
invoiceProcessing : any;
partialPaid : any;
fullyPaid : any;
totalEntityCount : any;
balanceAmount : any;
invoiceProcessingAmount : any;
showAdvance: boolean;
showBtn:boolean = false;
// ----------------------

  constructor(
    private dataSharingService: DataSharingService, private commonService:CommonService,
    private toasterService: ToastrService, private fileService: FileService,
    private modalService: BsModalService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.activeProjects = this.dataSharingService.getProjects();
    this.xrCode = this.dataSharingService.getCpDetails()['xrCode'];
    if(this.dataSharingService.getCpDetails()['advancedPaymentsEligibility'] === 'No') {
      this.showAdvance = false;
    }
    else {
      this.showAdvance = true;
    }
    this.getLadderIncentive('project');

  }
  onRowClick(event){
    // console.log(event)
  }
  stats:any;
  getLadderIncentive(filterType){

    if(filterType=='project'){
      this.launchFilter = "All Launches";
    }
    
    let payload = {};
    payload["xrCode"] = this.xrCode;
    if(this.projectFilter!="All Projects" && this.projectFilter !=''){
        payload["project"] = this.projectFilter;
    }
    if(this.launchFilter!="All Launches" && this.launchFilter !=''){
      payload["launch"] = this.launchFilter;
    }
    this.commonService.viewLadderIncentive(payload).subscribe(
        (response)=>{
          if (response.success) {

            if (response.payload && response.payload.headerList){
                
              this.currentResultsHeader = response['payload']['headerList']
                const temp = this.currentResultsHeader.pop();
                this.currentResultsHeader.unshift(temp)

                this.currentResultsHeader.forEach((element) => {
                  if(element.title === 'Invoice Document') {
                    element.cellTemplate = this.actionTpl;
                  }
                  if(element.title === 'View Details') {
                    element.cellTemplate = this.detailsTpl
                    element.width = 100
                    console.log(this.detailsTpl)
                  }
                  if(element.title === 'Payment Status') {
                    element.cellTemplate = this.paymentTpl
                    element.width = 130
                  }
                })


            }
            
            if(this.projectFilter!="All Projects" && this.projectFilter !=''){
              if (response.payload && response.payload.cpLadder){
                this.currentResults = response['payload']['cpLadder'];
              }
              
          }

          else {

            if (response.payload && response.payload.cpLadder){
              this.currentResults = response['payload']['cpLadder'];
            }
            

          }
            
            this.stats=response.payload;
            if(this.projectFilter =="All Projects"){
              this.projectListforCp = response.payload.projects;
            }
            if(this.launchFilter =="All Launches"){
              this.launchesListforCp = response.payload.launches;
            }
            this.totalCPLadderInvoiceAmount = Math.ceil(response['payload']['totalCPLadderInvoiceAmount']);
            this.totalCpLadderInvoiceAmountPaid = Math.ceil(response['payload']['totalCpLadderInvoiceAmountPaid']);
            this.invoiceRaised = Math.ceil(response['payload']['invoiceRaised']);
            this.invoicePaymentStatus = response['payload']['invoicePaymentStatus'];
            this.invoiceProcessing = response['payload']['invoiceProcessing'];
            this.partialPaid = response['payload']['partialPaid'];
            this.fullyPaid = response['payload']['fullyPaid'];
            this.totalEntityCount = response['payload']["totalCount"];
            this.balanceAmount = response['payload']["balanceAmount"];
            this.invoiceProcessingAmount = response['payload']["invoiceProcessingAmount"];
          }
        },
        (error)=>{},
      );

  }
  getStatus() {
    this.showBtn = true;
    let payload = 
      {
  
        "xrCode": this.xrCode
    
    }
    if(this.projectFilter!="All Projects" && this.projectFilter !=''){
      payload["project"] = this.projectFilter;
    }
    if(this.launchFilter!="All Launches" && this.launchFilter !=''){
      payload["launch"] = this.launchFilter;
    }
    this.commonService.getCPEligible(payload).subscribe((response) => {
      if (response.success) {
        this.currentResultsHeader = response['payload']['headerList']
        this.currentResultsHeader.forEach((element) => {
          if(element.title === 'Invoice Document') {
            element.cellTemplate = this.actionTpl;
          }
          if(element.title === 'View Details') {
            element.cellTemplate = this.detailsTpl
            element.width = 167
            console.log(this.detailsTpl)
          }
          if(element.title === 'Payment Status') {
            element.cellTemplate = this.paymentTpl
            element.width = 130
          }
          
        })
        if(this.projectFilter!="All Projects" && this.projectFilter !=''){
          this.currentResults = response['payload']['cpLadder'];
      }
      else {
        this.currentResults = response['payload']['cpLadder'];
        console.log(this.currentResults)
  
      }
       
        this.stats=response.payload;
        if(this.projectFilter =="All Projects"){
          this.projectListforCp = response.payload.projects;
        }
        if(this.launchFilter =="All Launches"){
          this.launchesListforCp = response.payload.launches;
        }
      
      }
      
    })
  }
  changeRoute(item:any) {
    console.log(item)
    this.route.navigateByUrl("/"+item)
  }
  viewPlot(actionType, plotNumber?) {
 
    if(actionType === 'view')
    {
    this.showView = !this.showView
    let payload = {};
    payload["uniquePlotNumber"] = plotNumber;

    this.commonService.viewLadderIncentive(payload).subscribe(
      (response)=>{
        if (response.success) {
          this.currentResultsHeader = response['payload']['headerList']
          const temp = this.currentResultsHeader[this.currentResultsHeader.length - 2];
          this.currentResultsHeader.splice(this.currentResultsHeader.length-2,1)
          this.currentResultsHeader.unshift(temp);
          this.currentResultsHeader.forEach((element) => {
            if(element.title === 'Invoice Document') {
              element.cellTemplate = this.actionTpl;
            }
            if(element.title === 'View Details') {
              element.cellTemplate = this.detailsTpl
              element.width = 167
              console.log(this.detailsTpl)
            }
          })
          this.currentResults = response['payload']['cpLadder']
        }
      },
      (error)=>{},
    );
    }
    else {
      this.showView = !this.showView
      this.getLadderIncentive('project');
    }
   

  }
  onDocumentUpload(event, rowType) {
    let eligibilityStatus = rowType['eligibilityStatus'];
    let invoiceStatus = rowType['invoiceStatus'];
    if((!invoiceStatus && eligibilityStatus=="Yes") || invoiceStatus=="Rejected"){
    this.fileLength = event.target.files.length
    this.docsArr = []
     for (let index = 0; index < event.target.files.length; index++) {
       let uploadedFile = event.target.files[index];
       const fileInputForm = new FormData();
       const file: File = uploadedFile
       fileInputForm.append('file', file, file.name);
       var uploadFileName = uuid(); 
       fileInputForm.append("fileName", file.name);
       fileInputForm.append("functionInstanceName", "Activity");
       fileInputForm.append("entityType", "ACTIVITY_" + rowType._id);
       fileInputForm.append("entityRef", uploadFileName);  
       this.uploadFile(fileInputForm, rowType);
     }
    }
    else{
      if(invoiceStatus=="Invoice Uploaded" || invoiceStatus=="Accepted"){
        this.toasterService.error("Invoice has already been uploaded.")
      }
      else {
      this.toasterService.error("Not eligible to upload the file.")
      }
    }
 
   }
   uploadFile(formData: FormData, rowType) {
     this.fileService.upload(formData)
       .subscribe(
         response => {
           if (response && response["url"] && response["fileName"]) {
             
               let doc: any = {};
               doc.url = response["url"];
               doc.fileName = response["fileName"];
               doc.downloadFileUrl = response["downloadFileUrl"];
               doc.fullDataUrl = response["fullDataUrl"];
               doc.fullFileUrl = response["fullFileUrl"];
               this.docsArr.push(doc)
               this.submitDoc(rowType)
               
              
               
             
             }
             
           })
           
         };
 
 submitDoc(rowType) {
   if(this.fileLength == this.docsArr.length) {
     rowType["invoiceDocuments"] = this.docsArr[0];
     rowType['invoiceStatus'] = "Invoice Uploaded";
     if("_autoId" in rowType) {
       delete rowType._autoId
     }
     console.log("rowType", rowType)
     let payload = {
       "cpLadder": rowType
     }
     this.commonService.updateCPLadder(payload).subscribe((data) => {
       if(data && data.payload) {
         this.toasterService.success("File Uploaded Successfully")
         this.fileLength = 0;
       }
     })
 
   }
 
 }
 downloadFile(){
  // console.log(row);
  let fileName = this.rowData.invoiceDocuments.downloadFileUrl;
  console.log(fileName);
  this.fileService.download(fileName).subscribe((data) => {

  })
}

viewImage(content: TemplateRef<any>, rowItem) {
  this.rowData = rowItem
  if(this.rowData.invoiceDocuments)
  {
  this.getDownloadFileObjectUrl(this.rowData.invoiceDocuments)
  this.imageRef = this.modalService.show(content)
  }
  else {
    this.toasterService.error("Please Upload File to preview")
  }

}
exportFile() {
    
  let element = document.getElementById('excel-table');
  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */  
  XLSX.writeFile(wb, this.displayHeader+".xlsx");

}
downloadInvoiceFile(){
  
  this.fileService.download("/api/downloadDataFile/FILEREADER_TEST/FILEREADER_TEST/Test/null/Channel Partner - Invoice Format.xlsm").subscribe((data) => {

  })
}
getDownloadFileObjectUrl(doc) {
  console.log(doc)
  
      this.fileService.download(doc.downloadFileUrl, true).subscribe(
        objectUrl => {
          if (objectUrl) {
            var type = (objectUrl.split(';')[0]).replace("data:","");
            fetch(objectUrl)
            .then(res => res.blob())
            .then(blob => {
              const file = new File([blob], doc.fileName,{ type: type })
              this.pdfSrc = (file);
            })
            // this.downloadFileObjectUrl = objectUrl;
          }
        }
      );
    }
  


       
  
}
