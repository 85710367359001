<div class="row" [hidden]="!stats">
    <div class="col-lg-12">
        <div class="card outer__card" style="padding:10px;">
        <div class="row">
            <div class="col-xxl-1 col-md-1 d-none d-md-block">
                
                <div class="card sales-card">

                    <a href="javascript:void(0);" class="headerLink" [routerLink]="['/projects']" routerLinkActive="router-link-active" >
                        <div class="card-body case__box">
                            <img class="card__img" src="./assets/images/dashboard.png" style="margin-bottom: 10px;">
                            <div class="col-12 card__outer">
                                <span class="pt-1 card__para">
                                    DASHBOARD</span>
                            </div>
                        </div>
                    </a>

                </div>
            </div>
            <div class="col-xxl-1 col-md-1 d-none d-md-block">
                
                <div class="card sales-card">

                    <a href="javascript:void(0);" class="headerLink" [routerLink]="['/payments']" routerLinkActive="router-link-active" >
                        <div class="card-body case__box">
                            <img class="card__img" src="./assets/images/brokerage.png" style="margin-bottom: 10px;">
                            <div class="col-12 card__outer">
                                <span class="pt-1 card__para">
                                    BASE EARNINGS</span>
                            </div>
                        </div>
                    </a>

                </div>
            </div>
            <div class="col-xxl-1 col-md-1 d-none d-md-block">
                <div class="card  sales-card" style="padding-bottom:0px;">

                    <a href="javascript:void(0);" class="headerLink" [routerLink]="['/ladder']" routerLinkActive="router-link-active" >
                        <div class="card-body case__box">
                            <img class="card__img" src="./assets/images/ladder.png">
                            <div class="col-12 card__outer">
                                <span class="pt-1 card__para"
                                    >LADDER INCENTIVE</span>
                            </div>
                        </div>
                    </a>

                </div>
            </div>
            <div class="col-xxl-1 col-md-1 d-none d-md-block">
                <div class="card sales-card" style="padding-bottom:0px;">

                    <a href="javascript:void(0);" class="headerLink" [routerLink]="['/app']" routerLinkActive="router-link-active" >
                        <div class="card-body case__box">
                            <img class="card__img" src="./assets/images/applications.png">
                            <div class="col-12 card__outer">
                                <span class="pt-1 card__para">APPLICATION INCENTIVE</span>
                            </div>
                        </div>
                    </a>

                </div>
            </div>
            <div class="col-xxl-1 col-md-1 d-none d-md-block">
                <div class="card sales-card" style="padding-bottom:0px;">

                    <a href="javascript:void(0);" class="headerLink" [routerLink]="['/advance']" routerLinkActive="router-link-active" >
                        <div class="card-body case__box">
                            <img class="card__img" src="./assets/images/AdvancedPayements.png">
                            <div class="col-12 card__outer">
                                <span class="pt-1 card__para">ADVANCE PAYMENTS</span>
                            </div>
                        </div>
                    </a>

                </div>
            </div>
            <div class="col-md-6 d-block d-sm-none">
                <select class="form-control" style="width:100%;" (change)="changeRoute($event.target.value)" >
                    <option value="none" selected disabled hidden>Select Option</option>
                    <option value="projects">Dashboard</option>
                    <option value="payments">Base Earnings</option>
                    <option value="ladder">Ladder Incentive</option>
                    <option value="app">Application Incentive</option>
                    <option value="advance">Advance Payments</option>
                </select>

            </div>
        
            <div class="col-xxl-2 col-md-2 mobile-drop">
                <select [(ngModel)]="projectFilter" class="form-control" (change)="getBrokerageIncentive()">
                    <option [value]="'All Projects'" selected>All Projects</option>
                    <option [value]="item" *ngFor="let item of projectListforCp">{{item}}</option>
                </select>
            </div> 
        </div>
    </div>
    <div class="row" style="margin-top:3%;">
        <div class="col-lg-12">
            <div class="card project__card">
                <p>Project Name: {{projectFilter}}</p>
            </div>
        </div>
    </div>
        <div class="row">
           
          <div class="col-lg-12">

              <div class="card table__card">
                  <div class="row">
                <div class="col-xxl-3 col-4 col-sm-4 col-md-3">
                
                    <div class="card info-card mb-2 shadow-sm bg-white rounded">

                        
                            <div class="card-body cards">
                              
                                <div class="card__outer">
                                    <span class="pt-1 card__para">
                                        Overall Advance Amount</span>
                                </div>
                                <p style="text-align: center; margin-bottom: 8px; font-weight: 700;">₹ {{totalAmount}}</p>
                            </div>
                        

                    </div>
                </div>

                <div class="col-xxl-3 col-4 col-sm-4 col-md-3">
                
                    <div class="card info-card mb-2 shadow-sm bg-white rounded">

                        
                            <div class="card-body cards">
                              
                                <div class="card__outer">
                                    <span class="pt-1 card__para">
                                        Total Adjusted Advance</span>
                                </div>
                                <p style="text-align: center; margin-bottom: 8px; font-weight: 700;">₹ {{advanceAdjustedAmount}}</p>
                            </div>
                       

                    </div>
                </div>
                <div class="col-xxl-3 col-4 col-sm-4 col-md-3">
                
                    <div class="card info-card mb-2 shadow-sm bg-white rounded">

                        
                            <div class="card-body cards">
                              
                                <div class="card__outer">
                                    <span class="pt-1 card__para">
                                        Total Advance Count</span>
                                </div>
                                <p style="text-align: center; margin-bottom: 8px; font-weight: 700;"> {{totalAdvancesCount}}</p>
                            </div>
                        

                    </div>
                </div>
                <div class="col-xxl-3 col-4 col-sm-4 col-md-3">
                
                    <div class="card info-card mb-2 shadow-sm bg-white rounded">

                        
                            <div class="card-body cards">
                               
                                <div class="card__outer">
                                    <span class="pt-1 card__para">
                                        Advances In Progress</span>
                                </div>
                                <p style="text-align: center; margin-bottom: 8px; font-weight: 700;"> {{advanceRedeemInProgressCount}}</p>
                            </div>
                        

                    </div>
                </div>
                <!-- <div class="col-xxl-3 col-4 col-sm-4 col-md-3">
                
                    <div class="card info-card mb-2 shadow-sm bg-white rounded">

                        
                            <div class="card-body cards">
                              
                                <div class="card__outer">
                                    <span class="pt-1 card__para">
                                        Advance Adjusted Completed Count</span>
                                </div>
                                <p style="text-align: center; margin-bottom: 8px; font-weight: 700;">  {{advanceRedeemedCount}}</p>
                            </div>
                        

                    </div>
                </div> -->
                
              </div>
            </div>
          </div>


        </div>
        <div class="row" style="margin-top:2%; position: relative;">
            <div class="col-lg-12">
                <div class="row">
                
                    <div class="col-md-12 choose_file">
                        <button class="btn btn-info" style="float:right; margin-bottom: 10px; margin-right:10px" (click)="downloadInvoiceFile()"><i class="bi bi-download"></i> Invoice</button>
                        <a class="btn btn-primary mb-3 export-btn" style="float:right; margin-right: 5px;" type="submit" (click)="exportFile()"> <i class="bi bi-filetype-csv"></i> Export to CSV</a>
                    </div>
                </div>
                <button  *ngIf="showView" type="button ml-2" class="btn btn-warning close-btn" (click)="viewPlot('close')"><i class="bi bi-x" style="font-size: 16px;"></i></button>
                <ngx-datatable
                    id="excel-table"
                    #table
                    tableClass="table table-striped table-bordered table-hover"
                    [columns]="currentResultsHeader"
                    [options]="options"
                    [tableId]="'randomId'"
                    [data]="currentResults"
                    (rowClick)="onRowClick($event)"
                > 
                <ng-template #actionTpl let-row let-rowIndex="rowIndex" let-columnValue="columnValue">
                    <div class="row" style="display: flex;" >
                        <div style="margin-left: 10px;" class="choose_file" *ngIf="(row.eligibilityStatus == 'Yes' && !row.invoiceStatus) || (row.eligibilityStatus == 'Yes' && row.invoiceStatus=='Rejected')">
                            <button type="button" class="btn btn-primary upload-btn"><i class="bi bi-upload" style="font-size: 12px;"></i></button>
                            <input name="img" type="file" accept=".pdf, .PDF"   (change)="onDocumentUpload($event, row)" />
                        </div>
                        <div style="margin-left: 10px;" *ngIf="row.invoiceDocuments && row.invoiceDocuments.downloadFileUrl">
                            <button type="button ml-2" class="btn btn-info upload-btn" (click)="viewImage(pdfImage, row)"><i class="bi bi-eye" style="font-size: 12px;"></i></button>
                        </div>
                    </div>

                </ng-template>
                <ng-template #detailsTpl let-row let-rowIndex="rowIndex" let-columnValue="columnValue">
                    <div class="row" style="display: flex;  justify-content: space-evenly;" >
                        
                            <button type="button" class="btn btn-primary upload-btn" (click)="viewPlot('view', row.uniquePlotNumber)"><i class="bi bi-eye" style="font-size: 12px;"></i> View</button>
                           
                        
                        
                    </div>

                </ng-template>
                <ng-template #startTpl let-row let-rowIndex="rowIndex" let-columnValue="columnValue">
                    <span>{{row.startDate | date:'short'}}</span>

                </ng-template>
        
                </ngx-datatable> 
            </div>
        </div>

    </div>
</div>

<ng-template #pdfImage>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Preview</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="imageRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <ngx-imageviewer #viewerwrapper [width]="400" [height]="400" [src]="pdfSrc"
               ></ngx-imageviewer>
            
               <!--  <i class="bi bi-filetype-docx pdf__icon"></i>
                <p>{{ item.fileName }}</p>
                <button class="btn-primary" (click)="downloadPDF(item.downloadURL)">Download</button>
 -->
            </div>
            <button style="margin: 0 auto; margin-top: 10px;" type="button" class="btn btn-info" (click)="downloadFile()">Download</button>
        </div>
        

    </div>
</ng-template>
