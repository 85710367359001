import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cp-reports',
  templateUrl: './cp-reports.component.html',
  styleUrls: ['./cp-reports.component.css']
})
export class CpReportsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
