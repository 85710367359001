import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { not } from '@angular/compiler/src/output/output_ast';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private notifications = new Subject<any>();
    notificationObservable = this.notifications.asObservable();
    private logoutSubject = new Subject<any>();


    constructor(
        private httpClient: HttpClient,
        private envData: DataSharingService,
        private router: Router
    ) { }
    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    toggleBlock: boolean = true;

    sendOtp(payload: any): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.sendVerifyOTP} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }
    verifyOtp(payload: any): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.sendVerifyOTP} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }

    getEligible(payload: any): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.fetchEligible} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }
    getCPEligible(payload: any): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.fetchCPEligible} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }
    getCPByPhone(payload: any): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.cpbyPhone} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }
    getCPDashboardData(payload: any): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.cpdashboard} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }
    getActiveProjects(): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.activeProjects} `;
        const payload =  {"status":"ACTIVE"};
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }
    getBrokerageIncentive(payload): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.brokerageIncentive} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }
    viewBrokerageIncentive(payload): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.viewBrokerage} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }
    viewLadderIncentive(payload): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.viewIncentive} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }
    getApplicationIncentive(payload): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.applicationIncentive} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }

    getApplicationIncentive_copy(payload): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.applicationIncentive_copy} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }
    getAdvanceIncentive(payload): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.applicationAdvance} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }
    getLadderIncentive(payload): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.ladderIncentive} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }

    updateNetDeals(payload): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.updateNetDeals} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }

    updateAppDeals(payload): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.updateAppDoc} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }

    fetchDeals(payload): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.fetchAppDoc} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }
    
    updateCPLadder(payload): Observable<any> {
        const subject = new Subject<any>();
        const url = `${environment.rootUrl + this.envData.updateCPLadder} `;
        this.httpClient.post<any>(
            url,
            payload,
            {
                observe: 'response',
                reportProgress: true,
                withCredentials: true
            }
        ).subscribe(
            (response: HttpResponse<any>) => {
                if (response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                subject.error(err);
            }
        );
        return subject.asObservable();
    }

    logout(rediredUrl?: string): Observable<any> {
       
        const url = `${ environment.rootUrl + this.envData.logout}`;
    
       /*  if (rediredUrl && this.DISALLOWED_RETURN_URLS.includes(rediredUrl)) {
          rediredUrl = null;
        } */
    
        this.httpClient.get<any>(
          url,
          {
             headers: this.httpHeaders, 
            observe: 'response',
            reportProgress: true,
            withCredentials: true
          }
        )
          .subscribe(
            response => {
              // remove user from local storage to log user out
              this.logoutSubject.next();
             /*  this.universalUser.removeUser();
              this.universalUser.removeCompany();
              this.communicationService.clearEverything();
              this.applicationService.clearAll(); */
              localStorage.clear();
             /*  if(document.getElementById('loadedCssFile')){
                document.getElementById('loadedCssFile')['disabled'] = true;
              } */
             
              if (rediredUrl && rediredUrl.trim().length > 0) {
                this.router.navigateByUrl('/login');
              }/*  else {
                this.router.navigate(['/login']);
              } */
            },
            error => {
    
            }
          )
    
    
    
        return this.logoutSubject.asObservable();
      }
    
      authenticateOauth(token: string): Observable<any> {
     /*    const envData = this.environmentData.getEnvData(); */
        const subject = new Subject<any>();
    
        if (token) {
          const url = `${environment.rootUrl + this.envData.authClient}`;
    
          const body = {};
          body["token"] = token;
    
          this.httpClient.post<any>(
            url,
            body,
            {
              headers: this.httpHeaders,
              observe: 'response',
              reportProgress: true,
              withCredentials: true
            }
          )
            .subscribe(
              (response: HttpResponse<any>) => {
                if (response.body) {
                  subject.next(response.body);
                }
              },
              (err: HttpErrorResponse) => {
                // All errors are handled in ErrorInterceptor, no further handling required
                // Unless any specific action is to be taken on some error
    
                subject.error(err);
              }
            );
          return subject.asObservable();
        }
      }
}



export class DataSharingService {
    private notifications = new Subject<any>();
    mobileNo:any;
    projects:any[] = [];
    cpDetails:any = {};

    notificationObservable = this.notifications.asObservable();
    setMobileNo(payload){
        localStorage.setItem("mobileNo",JSON.stringify(payload));
    }
    getMobileNo(){
        return JSON.parse(localStorage.getItem("mobileNo"));
        
    }
    setProjects(payload){
        localStorage.setItem("projects",JSON.stringify(payload));
    }
    getProjects(){
        return JSON.parse(localStorage.getItem("projects"));
    }
    setCpDetails(payload){
        console.log(payload)
        localStorage.setItem("cpDetails",JSON.stringify(payload));
    }
    getCpDetails(){
        return JSON.parse(localStorage.getItem("cpDetails"));
    }
    setXRCode(payload){
        localStorage.setItem("xrCode",JSON.stringify(payload));
    }
    getXRCode(){
        return JSON.parse(localStorage.getItem("xrCode"));
    }
    sendVerifyOTP: string = "/api/lambdav2/HoablgenerateSendVerifyOtp";
    cpbyPhone: string = "/flow-api/route/execute/fetchCPMasterByMobileNumber";
    activeProjects: string = "/flow-api/route/execute/fetchProjectsByActiveState";
    cpdashboard: string = "/flow-api/route/execute/fetchDashboardData";
    interfaceService:string = "/flow/interfaces";
    fileUploadUrl:string = "/api/uploadData";
    deleteDataFile:string = "/api/deleteDataFile";
   /*  brokerageIncentive: string = "/flow-api/route/execute/fetchNetDealsByXRCodeProject"; */
    brokerageIncentive: string = "/flow-api/route/execute/fetchNetDealsByXRCodeProjectGroupByPlotNo";
    fetchEligible:string= "/flow-api/route/execute/fetchEligibleOnly";
    fetchCPEligible:string= "/flow-api/route/execute/fetchCpLaddEligibleOnly";
    
    viewBrokerage:string ="/flow-api/route/execute/fetchNetDealsByPlotNumber";
    applicationIncentive: string = "/flow-api/route/execute/fetchApplicationsByXRCodeProject";
    applicationIncentive_copy: string = "/flow-api/route/execute/fetchApplicationsByXRCodeProject_copy";
    applicationAdvance: string = "/flow-api/route/execute/fetchAdvancePaymentByXRCodeProject";
    /* ladderIncentive: string = "/flow-api/route/execute/fetchCPLadderByXRCodeProject"; */
    ladderIncentive: string = "/flow-api/route/execute/fetchCPLadderByXRCodeProjectGroupByPlotNo";
    viewIncentive:string ="/flow-api/route/execute/fetchCPLadderByPlotNumber";
    updateNetDeals:string = "/flow-api/route/execute/updateNetDeals";
    updateAppDoc:string = "/flow-api/route/execute/updateAppDoc";
    fetchAppDoc:string= "/flow-api/route/execute/fetchAppDoc";
    updateCPLadder:string = "/flow-api/route/execute/updateCPLadder";
    logout:string = "/flow/logout"
    authClient:string ="/flow/oauth/"
    constructor(

    ) { }

}

