import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoaderService } from '../loader.service';
import { CommonService } from './commom.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router:Router,
    private loaderService: LoaderService,
    private commonService: CommonService
  ) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.show();
    const updatedHeaders = {};
    for (const key of req.headers.keys()) {
      if (key && req.headers.get(key)) {
        updatedHeaders[key] = req.headers.get(key);
      }
    }

    // if (this.universalUser.getXAuthToken()) {
    //   updatedHeaders['X-Auth-Token'] = this.universalUser.getXAuthToken();
    // }
      updatedHeaders['Authorization'] = "Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI2MjhmMTRlNWM5ZTc3YzAwMDFiZGU5MTAiLCJzdWIiOiJIT0FCTCIsImlzcyI6IjBINTdMNWdGa0pIem1HZHB0Tnp2MjN5Skd6MWMyQ0FaIn0.3OjqtjAGwDl0AwvlhsO3ZX5ftV9HJmS2bnFRXOJds4Y";

    req = req.clone({
      setHeaders: updatedHeaders
    });

    return next.handle(req).pipe(
      tap(event => {

      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            if (err && err.error && err.error.message) {
              console.error(err.error.message);
            }
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            if (err) {
              let statusCd = 0;
              let message = '';

              if (err.status) {
                statusCd = err.status;
              }
              if (err.error && err.error.message) {
                message = err.error.message;
              }
              if (statusCd !== 401) {
                console.error(message);
              } else if(statusCd == 401) {
                this.commonService.logout(this.router.url);
                this.router.navigateByUrl("/login");
              }
            }
          }
        }
      }),
      finalize(() => this.loaderService.hide())
    );
  }
}